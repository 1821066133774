/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import BoxGray from '../../components/BoxGray';
import BoxInformation from '../../components/BoxInformation';
import CircleLoading from '../../components/CircleLoading';
import ContentHeader from '../../components/ContentHeader';
import { BAGY_PAY_ACCOUNT_TYPE_LABEL } from '../../constants';
import { getAccount } from '../../services/account/getAccount';
import { getBankAccount } from '../../services/bankAccount/getBankAccount';
import { getMe } from '../../services/me/getMe';
import { AccountType } from '../../services/types/account.type';
import { BankAccountsType } from '../../services/types/bankAccount.type';
import { MeType } from '../../services/types/me.type';
import { formatTaxesType } from '../../utils/formatter';
import { BankAccountForm } from './BankAccountForm';
import styles from './styles';
import { AccountInfoLine } from './AccountInfoLine';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { showModal } from '../../redux/slices';
import { showToastError } from '../../components/Toast';
import { AddressType } from '../../services/types/address.type';
import { getAddressAcount } from '../../services/account/getAddressAccount';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

const MyAccount = () => {
    const classes = styles();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [account, setAccount] = useState<AccountType>();
    const [bankAccount, setBankAccount] = useState<BankAccountsType>();
    const [address, setAddress] = useState<AddressType>();
    const [isUpdateBankAccount, setUpdateBankAccount] = useState(false);
    const [me, setMe] = useState<MeType>({
        blipId: '',
        storeId: '',
        name: '',
        url: '',
        links: {
            facebookCatalogLink: '',
            storeLink: '',
        },
        address: {
            postalCode: '',
            street: '',
            number: '',
            details: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
        },
    });

    const loadBankAccount = async () => {
        try {
            setIsLoading(true);
            const bankAccountData = await getBankAccount().catch(() => null);
            if (bankAccountData) setBankAccount(bankAccountData);
            setUpdateBankAccount(false);
        } catch (error: any) {
            showErrorAsToast({
                error,
                message:
                    'Ocorreu um problema ao tentar carregar os dados bancários.',
                showToast: showToastError,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const loadAccount = useCallback(async () => {
        try {
            setIsLoading(true);
            const accountData = await getAccount();
            const addressData = await getAddressAcount().catch(() => null);
            const meData = await getMe();
            await loadBankAccount();
            setMe(meData);
            setAccount(accountData);
            setAddress(addressData);
        } catch (error: any) {
            showErrorAsToast({
                error,
                message:
                    'Ocorreu um problema ao tentar carregar os dados da sua conta.',
                showToast: showToastError,
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const showAlertModalWhenChangeBankAccount = () => {
        dispatch(
            showModal({
                data: {
                    buttonText: '',
                    title: 'Alteração de conta bancária',
                    message:
                        'Atenção, ao alterar sua conta bancária, uma nova validação de documentos deverá ser feita, você têm certeza?',
                    onYesClick: () => setUpdateBankAccount(true),
                    onNoClick: () => setUpdateBankAccount(false),
                },
                modalType: 'CONFIRM_MODAL',
            }),
        );
    };

    useEffect(() => {
        loadAccount();
    }, [loadAccount]);

    const {
        paymentTax: { bankSlip, creditCard, description, pix },
    } = account || { paymentTax: { taxes: [] } };

    const { url, links } = me;

    return (
        <div className={classes.container}>
            <ContentHeader iconName="icon-people" title="Minha Conta" />
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!!account && !isLoading && (
                <>
                    <div className={classes.informationBoxes}>
                        <BoxInformation
                            className={classes.informationBoxes}
                            spanTextOne="taxa venda cartão"
                            textInformation={
                                creditCard
                                    ? formatTaxesType(
                                          creditCard.type,
                                          creditCard.value,
                                      )
                                    : 'Tarifa'
                            }
                            iconName="icon-credit-card-coin"
                            spanTextTwo={description}
                        />
                        <BoxInformation
                            spanTextOne="TAXA VENDA BOLETO"
                            textInformation={
                                bankSlip
                                    ? formatTaxesType(
                                          bankSlip.type,
                                          bankSlip.value,
                                      )
                                    : 'Tarifa'
                            }
                            iconName="icon-bar-code"
                            spanTextTwo={description}
                        />
                        <BoxInformation
                            spanTextOne="taxa venda pix"
                            textInformation={
                                pix
                                    ? formatTaxesType(pix.type, pix.value)
                                    : 'Tarifa'
                            }
                            iconName="icon-tag-money"
                            spanTextTwo={description}
                        />
                    </div>
                    <div>
                        <div className={classes.personalInformation}>
                            <BoxGray>
                                <div className={classes.informationTable}>
                                    <h1 className={classes.headerTable}>
                                        Dados pessoais
                                    </h1>

                                    <AccountInfoLine
                                        label="Email"
                                        value={account.email}
                                    />
                                    <AccountInfoLine
                                        label="Tipo de conta"
                                        value={
                                            BAGY_PAY_ACCOUNT_TYPE_LABEL[
                                                account.type
                                            ]
                                        }
                                    />
                                    <AccountInfoLine
                                        label="Nome da loja"
                                        value={me.name}
                                    />
                                    <AccountInfoLine
                                        label="CPF"
                                        value={account.cpf}
                                    />
                                    <AccountInfoLine
                                        label="Nome completo"
                                        value={account.name}
                                    />
                                    <AccountInfoLine
                                        label="Data de nascimento"
                                        value={
                                            account.birthDate
                                                ? moment
                                                      .utc(
                                                          account.birthDate,
                                                          'YYYY-MM-DDTHH',
                                                      )
                                                      .format('DD/MM/YYYY')
                                                : ''
                                        }
                                    />
                                    <AccountInfoLine
                                        label="Endereço da loja"
                                        renderComponent={() => (
                                            <a
                                                href={links.storeLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                bagy.app
                                                {url || ''}
                                            </a>
                                        )}
                                    />
                                </div>
                            </BoxGray>
                        </div>
                        <div className={classes.personalInformation}>
                            <BoxGray>
                                <div className={classes.informationTable}>
                                    <div className={classes.headerBankAccount}>
                                        <h1 className={classes.headerTable}>
                                            {isUpdateBankAccount && !bankAccount
                                                ? 'Cadastre sua conta bancária'
                                                : 'Dados bancários'}

                                            {bankAccount && (
                                                <Button
                                                    typeButton="link"
                                                    onClick={() => {
                                                        const isClosing =
                                                            isUpdateBankAccount;
                                                        if (!isClosing) {
                                                            showAlertModalWhenChangeBankAccount();
                                                        }
                                                        setUpdateBankAccount(
                                                            !(
                                                                isUpdateBankAccount &&
                                                                bankAccount
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    {isUpdateBankAccount &&
                                                    bankAccount ? (
                                                        'Cancelar'
                                                    ) : bankAccount ? (
                                                        <Icon iconName="icon-gear" />
                                                    ) : (
                                                        ''
                                                    )}
                                                </Button>
                                            )}
                                        </h1>
                                    </div>
                                    {!isUpdateBankAccount && bankAccount ? (
                                        <>
                                            <AccountInfoLine
                                                label="Tipo de conta"
                                                value={
                                                    bankAccount.typeAccount ===
                                                    'C'
                                                        ? 'Conta Corrente'
                                                        : ' Conta Poupança'
                                                }
                                            />
                                            <AccountInfoLine
                                                label="Banco"
                                                value={bankAccount.bankName}
                                            />
                                            <AccountInfoLine
                                                label="Agência"
                                                value={bankAccount.agency}
                                            />
                                            <AccountInfoLine
                                                label="Número da conta"
                                                value={bankAccount.account}
                                            />
                                        </>
                                    ) : (
                                        <BankAccountForm
                                            bankAccountId={
                                                bankAccount
                                                    ? bankAccount.bankAccountId
                                                    : 0
                                            }
                                            isUpdate={isUpdateBankAccount}
                                            onBankAccountChange={
                                                loadBankAccount
                                            }
                                        />
                                    )}
                                </div>
                            </BoxGray>
                        </div>
                        <div className={classes.personalInformation}>
                            {address && (
                                <BoxGray>
                                    <div className={classes.informationTable}>
                                        <h1 className={classes.headerTable}>
                                            Endereço principal
                                        </h1>

                                        <AccountInfoLine
                                            label="CEP"
                                            value={address.zipcode}
                                        />
                                        <AccountInfoLine
                                            label="Endereço"
                                            value={address.street}
                                        />
                                        <AccountInfoLine
                                            label="Número"
                                            value={address.number}
                                        />
                                        <AccountInfoLine
                                            label="Bairro"
                                            value={address.neighborhood}
                                        />
                                        <AccountInfoLine
                                            label="Cidade"
                                            value={address.city}
                                        />
                                        <AccountInfoLine
                                            label="Estado"
                                            value={address.state}
                                        />
                                    </div>
                                </BoxGray>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MyAccount;
