import React from 'react';
import BoxGray from '../BoxGray';
import styles from './styles';

interface WithdrawalInformationProps {
    numberWithdrawal: string;
    description?: string;
}
const WithdrawalInformation = (props: WithdrawalInformationProps) => {
    const { numberWithdrawal, description } = props;
    const classes = styles();
    return (
        <BoxGray>
            <div className={classes.container}>
                <h1 className={classes.header}>Saque Nº {numberWithdrawal}</h1>
                <p>{description}</p>
            </div>
        </BoxGray>
    );
};
export default WithdrawalInformation;
