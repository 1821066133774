/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BoxGray from '../../components/BoxGray';
import BoxInformation from '../../components/BoxInformation';
import VisaPng from '../../assets/images/visa.png';
import PixPng from '../../assets/images/pix.png';
import HiperPng from '../../assets/images/hiper.png';
import HipercardPng from '../../assets/images/hipercard.png';
import EloPng from '../../assets/images/elo.png';
import BoletoPng from '../../assets/images/boleto.png';
import AmericanExpressPng from '../../assets/images/americanexpress.png';
import MasterCardPng from '../../assets/images/master.png';

import Table from '../../components/Table';
import {
    DataConfigType,
    HideArrowType,
    TableHeader,
    TableProps,
} from '../../components/Table/types';
import styles from './styles';
import ContentHeader from '../../components/ContentHeader';
import { getSales } from '../../services/getSales/getSales';
import { getBalance } from '../../services/balance/getBalance';
import { formatCurrency } from '../../utils/formatter';
import CircleLoading from '../../components/CircleLoading';
import { BalanceType } from '../../services/types/balancetype';
import {
    TRANSACTION_STATUS_COLOR,
    TRANSACTION_STATUS_LABEL,
} from '../../constants';
import { getBankAccount } from '../../services/bankAccount/getBankAccount';
import { hideModal, showModal } from '../../redux/slices';
import { Icon } from '../../components/Icon';

type SaleTableType = {
    transactionId: string;
    buyerName: string;
    amount: number;
    method: number;
    status: string;
    date: string;
    detailAction?: number;
};

type VindiPaymentMethodsType = {
    [k: string]: string;
};

const Home = () => {
    const classes = styles();
    const navigate = useNavigate();
    const [listSales, setListSales] = useState<SaleTableType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showTextInformation, toggleShowTextInformation] =
        useState<Boolean>(true);
    const [balance, setBalance] = useState<BalanceType>({
        resource: '',
        available: 0,
        pending: 0,
        transacted: 0,
    });
    const dispatch = useDispatch();

    const headers: TableHeader = {
        transactionId: { title: 'Transação', order: 0 },
        buyerName: { title: 'Comprador', order: 1 },
        amount: { title: 'Valor Total', order: 2 },
        method: { title: 'Método', order: 3 },
        status: { title: 'Status', order: 4 },
        date: { title: 'Data', order: 5 },
        detailAction: { title: '', order: 6 },
    };
    const idxColsHideOnMobile: number[] = [6, 5, 4, 3];
    const idxColsHideOnDesktop: number[] = [6];
    const hideArrowRight: HideArrowType = {
        media: 'tablet',
    };

    const dataConfig: DataConfigType<SaleTableType> = {
        status: {
            renderComponent: ({ value }) => {
                const status = TRANSACTION_STATUS_LABEL[value as string];
                const color = TRANSACTION_STATUS_COLOR[value as string];
                return (
                    <div
                        style={{
                            // TODO: Passar para arquivo style
                            display: 'inline-block',
                            padding: '6px 12px',
                            borderRadius: 8,
                            color,
                            border: `1px solid ${color}`,
                        }}
                    >
                        {status}
                    </div>
                );
            },
        },
        method: {
            renderComponent: ({ value }) => {
                const vindiPaymentMethods: VindiPaymentMethodsType = {
                    '3': VisaPng,
                    '4': MasterCardPng,
                    '5': AmericanExpressPng,
                    '6': BoletoPng,
                    '16': EloPng,
                    '20': HipercardPng,
                    '25': HiperPng,
                    '27': PixPng,
                };
                const source = vindiPaymentMethods[value as string];

                return <img src={source} alt="bandeira do cartão de credito" />;
            },
        },
        amount: {
            renderComponent: ({ value }) => (
                <div>{formatCurrency(Number(value))}</div>
            ),
        },
        date: {
            renderComponent: ({ value }) => (
                <div>
                    {moment
                        .utc(value, 'YYYY-MM-DDTHH:mm:ssZ')
                        .format('DD/MM/YYYY')}
                </div>
            ),
        },

        transactionId: {
            renderComponent: ({ value, rowData }) => {
                const { status } = rowData;
                const color = TRANSACTION_STATUS_COLOR[status as string];
                return (
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                bottom: '0.5%',
                                backgroundColor: color,
                                width: 8,
                                height: '100%',
                            }}
                        />
                        <div>{value}</div>
                    </div>
                );
            },
        },
    };
    const tableProps: TableProps<SaleTableType> = {
        headers,
        idxColsHideOnMobile,
        idxColsHideOnDesktop,
        listData: listSales,
        hideArrowRight,
        dataConfig,
        onClickRow: ({ detailAction }) =>
            navigate(`/transacoes/${detailAction}`),
    };

    const loadSales = async () => {
        setIsLoading(true);
        const { sales } = await getSales({ limit: 7 });
        const accountBalance = await getBalance();
        const salesTable: SaleTableType[] = sales.map<SaleTableType>(
            ({
                customer,
                orderNumber,
                payment,
                status,
                created,
                sellerPrice,
                id,
            }) => {
                const { name: buyerName } = customer;
                const { paymentMethodId } = payment;
                const { name } = status;
                return {
                    buyerName,
                    status: name,
                    date: created,
                    amount: sellerPrice,
                    method: paymentMethodId,
                    transactionId: orderNumber,
                    detailAction: id,
                };
            },
        );
        const bankData = await getBankAccount().catch(() => null);
        if (!bankData) {
            dispatch(
                showModal({
                    data: {
                        buttonText: 'Ir para Minha Conta',
                        message:
                            'Para que você possa realizar saques, cadastre sua conta bancaria em Minha Conta.',
                        title: 'Olá, você ainda não tem uma conta de banco cadastrada',
                        onClick: () => {
                            dispatch(hideModal());
                            navigate('/minha-conta');
                        },
                    },
                    modalType: 'DIALOG_MODAL',
                }),
            );
        }
        setListSales(salesTable);
        setBalance(accountBalance);
        setIsLoading(false);
    };

    useEffect(() => {
        loadSales();
    }, []);

    return (
        <div className={classes.container}>
            <ContentHeader iconName="icon-home" title="Início" />
            <div className={classes.information}>
                <BoxGray>
                    <div>
                        <div className={classes.headerInformationContainer}>
                            <h1 className={classes.headerInformation}>
                                ATENÇÃO! Alteração nas regras de pagamento de
                                saldo: nova regra de liquidação Bacen.
                            </h1>
                            <Icon
                                customStyles={classes.textInformationButton}
                                iconName={`${
                                    showTextInformation
                                        ? 'icon-chevron-down'
                                        : 'icon-chevron-up'
                                }`}
                                onClick={() =>
                                    toggleShowTextInformation(
                                        !showTextInformation,
                                    )
                                }
                            />
                        </div>
                        {showTextInformation && (
                            <p className={classes.textInformation}>
                                De acordo com a Resolução BCB n° 150 de
                                6/10/2021 do Banco Central, em breve os valores
                                das vendas feitas no cartão de crédito e
                                antecipações serão automaticamente liquidados
                                (pagos) em sua conta bancária um dia após serem
                                creditados.
                            </p>
                        )}
                    </div>
                </BoxGray>
            </div>
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && (
                <div>
                    <div className={classes.informationBoxes}>
                        <BoxInformation
                            className={classes.informationBox}
                            spanTextOne="SALDO DISPONÍVEL"
                            textInformation={formatCurrency(balance.available)}
                            iconName="icon-circle-money"
                            link="/solicitar-saque"
                            textLink="Solicitar saque"
                        />
                        <BoxInformation
                            spanTextOne="saldo a receber"
                            textInformation={formatCurrency(balance.pending)}
                            iconName="icon-arrows-apposite"
                        />
                    </div>
                    <div className={classes.tableContainer}>
                        <h1 className={classes.tableHeader}>Últimas vendas</h1>
                        <Table isLoading={isLoading} {...tableProps} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
