import React from 'react';
import styles from './styles';

const Settings = () => {
    const classes = styles();

    return <div className={classes.container}>Settings</div>;
};

export default Settings;
