import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'userInfo' | 'iconArrow' | 'iconPeople';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            margin: 5,
            wordBreak: 'break-word',
            '& span': {
                '&:first-child': {
                    fontWeight: 'bold',
                },
                marginTop: 3,
                display: 'inline-block',
            },
        },
        iconPeople: {
            color: `${theme.colors.primaryPure}`,
            fontSize: 21,
        },
        iconArrow: {
            color: `${theme.colors.primaryPure}`,
            fontSize: 11,
            alignSelf: 'flex-start',
        },
    }),
);

export default styles;
