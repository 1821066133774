import { AxiosError } from 'axios';
import { ShowToastProps } from '../../components/Toast';
import { BaseError } from './classes/BaseError';
import { ErrorConstants } from './classes/constants';

interface ShowErrorAsToastProps {
    showToast: ({ title, message, autoClose, onClose }: ShowToastProps) => void;
    title?: string;
    message: string;
    error: AxiosError | BaseError;
}

export const showErrorAsToast = ({
    showToast,
    title,
    message,
    error,
}: ShowErrorAsToastProps) => {
    const notShowModalError =
        error &&
        error instanceof BaseError &&
        error.errorType === ErrorConstants.expiredSession;
    if (notShowModalError) return;
    showToast({
        title: title || 'Ops',
        message,
    });
};
