/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { IMaskInput } from 'react-imask';
import BalanceWithdrawalBox from '../../components/BalanceWithdrawalBox';
import BankInformation from '../../components/BankInformation';
import Button from '../../components/Button';
import ContentHeader from '../../components/ContentHeader';
import styles from './styles';
import { BalanceType } from '../../services/types/balancetype';
import { BankAccountsType } from '../../services/types/bankAccount.type';
import { getBalance } from '../../services/balance/getBalance';
import { getBankAccount } from '../../services/bankAccount/getBankAccount';
import CircleLoading from '../../components/CircleLoading';
import {
    showToastError,
    showToastSuccess,
    showToastWarning,
} from '../../components/Toast';
import { createWithdrawal } from '../../services/withdrawals/createWithdrawal';
import { withdrawalValidationShema } from './withdrawalValidationSchema';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

const RequestWithdrawal = () => {
    const classes = styles();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isRequestLoading, setIsRequestLoading] = useState(false);
    const [balance, setBalance] = useState<BalanceType>();
    const [bankAccount, setBankAccount] = useState<BankAccountsType>();

    const loadRequestWithdrawal = async () => {
        setIsLoading(true);
        const balanceData = await getBalance();
        const bankAccountData = await getBankAccount();
        setBankAccount(bankAccountData);
        setBalance(balanceData);
        setIsLoading(false);
    };
    const requestWithdrawal = async (
        bankAccountId: string,
        cashValue: string,
    ) => {
        try {
            setIsRequestLoading(true);
            await createWithdrawal(bankAccountId, cashValue);
            await loadRequestWithdrawal();
            showToastSuccess({
                title: 'Sucesso! Saque efetuado',
                message: 'Aguarde o pagamento cair na sua conta',
                onClose: () => {
                    navigate('/saques');
                },
            });
        } catch (error: any) {
            showErrorAsToast({
                error,
                message: 'Ocorreu um problema ao tentar solicitar o seu saque.',
                showToast: showToastError,
            });
        } finally {
            setIsRequestLoading(false);
        }
    };

    const { available } = balance || { available: 0 };

    const submit = (formData: { withdrawalValue: string }) => {
        setIsSubmitting(true);
        const { withdrawalValue } = formData;
        if (+withdrawalValue > available) {
            showToastWarning({
                title: 'Atenção',
                message: 'O valor de saque é maior do que o saldo disponivel.',
            });
        } else {
            requestWithdrawal(
                `${bankAccount?.bankAccountId}`,
                `${withdrawalValue}`,
            );
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        loadRequestWithdrawal();
    }, []);

    const { values, errors, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                withdrawalValue: '1',
            },
            onSubmit: submit,
            validationSchema: withdrawalValidationShema,
        });

    return (
        <div className={classes.container}>
            <ContentHeader
                iconName="icon-hand-money"
                title="Retirada para conta bancária"
            />
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && (
                <form onSubmit={handleSubmit}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            {bankAccount && (
                                <BankInformation
                                    bank={bankAccount?.bankName}
                                    bankBranch={bankAccount.agency}
                                    accountNumber={bankAccount.account}
                                    typeaccount="Conta corrente"
                                />
                            )}
                        </div>
                        <div className={classes.content}>
                            <BalanceWithdrawalBox
                                title="Valor a retirar"
                                isRequestWithdrawal
                                balanceValue={available}
                                inputWithdrawal={
                                    <IMaskInput
                                        style={{
                                            outline: 'none',
                                            cursor: 'pointer',
                                        }}
                                        mask={Number}
                                        radix=","
                                        mapToRadix={['.']}
                                        value={values.withdrawalValue}
                                        unmask
                                        name="withdrawalValue"
                                        thousandsSeparator="."
                                        scale={2}
                                        min={1}
                                        onAccept={(data) => {
                                            handleChange('withdrawalValue')(
                                                `${data}`,
                                            );
                                        }}
                                        onBlur={handleBlur}
                                    />
                                }
                                errors={errors.withdrawalValue}
                            />
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            title={
                                isRequestLoading
                                    ? 'Processando...'
                                    : 'Solicitar saque'
                            }
                            typeButton="default"
                            disabled={isSubmitting}
                        />

                        <Button
                            title="< Voltar"
                            typeButton="link"
                            onClick={() => navigate('/saques')}
                        />
                    </div>
                </form>
            )}
        </div>
    );
};
export default RequestWithdrawal;
