import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'iconContainer'
    | 'informationContainer'
    | 'container'
    | 'spanOne'
    | 'spanTwo'
    | 'linkTablet'
    | 'linkStyles'
    | 'linkContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 20,
            background: theme.colors.backgroundGray,
            border: `1px solid ${theme.colors.borderColor}`,
            borderRadius: 16,
            margin: '8px 0',
            padding: 16,
            [theme.breakpoints.tablet]: {
                flexDirection: 'row',
                borderColor: theme.colors.primaryPure,
                width: '100%',
                maxWidth: 340,
                padding: '16px 24px',
            },
        },
        iconContainer: {
            color: theme.colors.primaryPure,
            alignContent: 'center',
            position: 'relative',
            '& :before': {
                fontSize: 50,
                boxSizing: 'content-box',
                margin: '0 16px 0 0',
                textAlign: 'left',
                [theme.breakpoints.tablet]: {
                    fontSize: 60,
                },
            }
        },
        informationContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'start',
            lineHeight: 1.4,
            [theme.breakpoints.tablet]: {
                lineHeight: 1,
            },
            '& h1': {
                color: theme.colors.textNeutral,
                fontSize: 20,
                fontWeight: 900,
                justifyContent: 'end',
                fontFamily: 'Nunito-black',
                [theme.breakpoints.tablet]: {
                    fontSize: 24,
                },
            },
            '& span': {
                fontWeight: 400,
                fontSize: 10,
                lineHeight: 2,
            },
        },
        spanOne: {
            textTransform: 'uppercase',
            color: theme.colors.textNeutral,
        },
        spanTwo: {
            fontWeight: 400,
            fontSize: 12,
            color: theme.colors.textNeutral,
        },

        linkStyles: {
            fontWeight: 500,
            fontSize: 12,
            '& a ': {
                color: theme.colors.secondaryPure,
                [theme.breakpoints.tablet]: {
                    textDecoration: 'none',
                },
            },
            '& a:hover ': {
                color: theme.colors.primaryPure,
            },
        },
        linkTablet: {
            display: 'none',
            [theme.breakpoints.tablet]: {
                display: 'flex',
                marginTop: 10,
            },
        },
        linkContainer: {
            display: 'flex',
            alignSelf: 'end',
            justifyContent: 'end',
            flex: 1,
            '& a ': {
                color: theme.colors.primaryPure,
            },
            [theme.breakpoints.tablet]: {
                display: 'none',
            },
        },
    }),
);

export default styles;
