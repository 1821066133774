/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from '../Menu';
import styles from './styles';

const ANIMATION_TIME = 200;

interface MenuSideBarProps {
    isOpen?: boolean;
    onCloseMenu?: () => void;
}

const MenuSideBarMobile = ({ isOpen, onCloseMenu }: MenuSideBarProps) => {
    const location = useLocation();
    const [isInnerOpen, setIsInnerOpen] = useState(isOpen);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const classes = styles();

    const open = () => {
        setIsOverlayOpen(true);
        setTimeout(() => {
            setIsMenuOpen(true);
        }, ANIMATION_TIME);
    };

    const close = () => {
        setIsMenuOpen(false);
        setIsOverlayOpen(false);
        setTimeout(() => {
            setIsInnerOpen(false);
            if (onCloseMenu) {
                onCloseMenu();
            }
        }, ANIMATION_TIME);
    };

    useEffect(() => {
        setIsInnerOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isInnerOpen) {
            open();
            return;
        }
        close();
    }, [isInnerOpen]);

    useEffect(() => {
        close();
    }, [location]);

    return isInnerOpen ? (
        <div
            onClick={() => close()}
            className={`${classes.container} ${isOverlayOpen ? '-isOpen' : ''}`}
        >
            <div
                className={`${classes.menuContainer} ${
                    isMenuOpen ? '-isOpen' : ''
                }`}
            >
                <Menu />
            </div>
        </div>
    ) : null;
};

export default MenuSideBarMobile;
