import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            backgroundColor: theme.colors.backgroundGray,
            border: `1px solid ${theme.colors.borderColor}`,
            padding: 16,
            borderRadius: theme.border.radius,
            [theme.breakpoints.desktop]: {
                borderRadius: theme.border.radiusBig,
                padding: 24,
            },
        },
    }),
);

export default styles;
