import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'success' | 'error' | 'warning' | 'icon';
const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            marginBottom: 10,
            color: theme.colors.white,
            borderRadius: '0px 0px 8px 8px',
            fontSize: 16,
            fontFamily: 'Nunito',
            '& p': {
                margin: 0,
                padding: 5,
                fontWeight: 400,
                '& b': {
                    fontWeight: '900',
                },
            },
        },
        success: {
            backgroundColor: theme.colors.backgroundGreen,
        },
        error: {
            backgroundColor: theme.colors.backgroundRed,
        },
        warning: {
            backgroundColor: theme.colors.backgroundOrange,
        },
        icon: {
            fontSize: 24,
        },
    }),
);

export default styles;
