import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'outline' | 'default' | 'link' | 'buttonContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        buttonContainer: {
            borderRadius: 60,
            width: '100%',
            textAlign: 'center',
            padding: 10,
            cursor: 'pointer',
            border: '1px solid',
            lineHeight: '16px',
            fontWeight: 900,
            fontSize: 15,
            '&:hover': {
                transition: '0.4s',
                opacity: '0.8',
            },
        },
        default: {
            backgroundColor: theme.colors.primaryPure,
            color: theme.colors.white,
            borderColor: theme.colors.primaryPure,
            cursor: 'pointer',

            '&:hover': {
                color: theme.colors.primaryPure,
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.primaryPure,
            },
        },
        outline: {
            backgroundColor: theme.colors.white,
            color: theme.colors.primaryPure,
            borderColor: theme.colors.primaryPure,

            '&:hover': {
                color: theme.colors.white,
                backgroundColor: theme.colors.primaryPure,
                borderColor: theme.colors.primaryPure,
            },
        },

        link: {
            backgroundColor: theme.colors.white,
            color: theme.colors.secondaryPure,
            cursor: 'pointer',
            fontSize: '15px',
            border: 'none',
            width: 'auto',
            '&:hover': {
                opacity: '0.8',
            },
        },
    }),
);

export default styles;
