import React from 'react';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from './router/Router';
import { persistor, store } from './redux';
import { useGlobalStyles, bagyPayTheme } from './styles';
import ToastWrapper from './components/Toast';
import { GlobalModal } from './components/GlobalModal';

function App() {
    useGlobalStyles();
    return (
        <ThemeProvider theme={bagyPayTheme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <GlobalModal />
                    <ToastWrapper />
                    <Router />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
