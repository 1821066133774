import { callPost } from '../graphql';
import { DELETE_BANK_ACCOUNT } from '../graphql/mutations';

export const deleteBankAccount = async (bankAccountId: number) => {
    const { data: axiosData } = await callPost(DELETE_BANK_ACCOUNT, {
        bankAccountId,
    });
    const { data } = axiosData;
    return data.bagyPayDeleteBankAccount;
};
