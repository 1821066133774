/* eslint-disable react/require-default-props */

import React, { useCallback } from 'react';

import 'react-toastify/dist/ReactToastify.min.css';

import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import { Icon } from '../Icon';
import styles from './styles';
import { IconNames } from '../Icon/icon-names.type';

interface ToastContentProps {
    title: string;
    message: string;
}

interface ToastIconProps {
    iconName: IconNames;
}

export interface ShowToastProps {
    title: string;
    message: string;
    autoClose?: number;
    onClose?: <T = {}>(props: T) => void;
}

const ToastWrapper = () => {
    const classesToast = styles();

    const getStyleByType = useCallback(
        (type: TypeOptions) => {
            const getStyle = (toastTypeStyle: string) =>
                `Toastify__toast Toastify__toast-theme--light Toastify__toast--${type} ${classesToast.container} ${toastTypeStyle}`;

            if (type === 'success') return getStyle(classesToast.success);
            if (type === 'error') return getStyle(classesToast.error);
            return getStyle(classesToast.warning);
        },
        [classesToast],
    );

    return (
        <ToastContainer
            toastClassName={({ type = 'success' } = {}) => getStyleByType(type)}
            position="top-right"
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
        />
    );
};

const ToastContent = ({ title, message }: ToastContentProps) => (
    <p>
        <b>{title}!</b> {message}
    </p>
);

const ToastIcon = ({ iconName }: ToastIconProps) => {
    const classesToast = styles();
    return <Icon iconName={iconName} customStyles={classesToast.icon} />;
};

export const showToastSuccess = ({
    title,
    message,
    autoClose = 2500,
    onClose,
}: ShowToastProps) => {
    toast.success(<ToastContent title={title} message={message} />, {
        icon: <ToastIcon iconName="icon-check" />,
        autoClose,
        onClose,
    });
};

export const showToastWarning = ({
    title,
    message,
    autoClose = 2500,
    onClose,
}: ShowToastProps) => {
    toast.warning(<ToastContent title={title} message={message} />, {
        icon: <ToastIcon iconName="icon-check" />,
        autoClose,
        onClose,
    });
};

export const showToastError = ({
    title,
    message,
    autoClose = 2500,
    onClose,
}: ShowToastProps) => {
    toast.error(<ToastContent title={title} message={message} />, {
        icon: <ToastIcon iconName="icon-check" />,
        autoClose,
        onClose,
    });
};

export default ToastWrapper;
