/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes, useState } from 'react';
import Button from '../Button';
import { Icon } from '../Icon';
import { IconNames } from '../Icon/icon-names.type';
import styles from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    label?: string;
    error?: string;
    rightIcon?: IconNames;
    onClickRightIcon?: () => void;
    iconName?: IconNames;
}

export const Input = (props: InputProps) => {
    const classes = styles();
    const [isFocus, setFocus] = useState(false);

    const {
        label,
        error,
        rightIcon,
        onClickRightIcon,
        onFocus,
        onBlur,
        className,
        ...inputProps
    } = props;

    return (
        <label className={`${classes.container} ${className || ''}`}>
            {label && <span className={classes.label}>{label}</span>}
            <div
                className={`${classes.inputContainer} ${
                    isFocus ? '-focus-style' : ''
                }`}
            >
                <input
                    className={classes.input}
                    {...inputProps}
                    onFocus={(ev) => {
                        if (onFocus) onFocus(ev);
                        setFocus(true);
                    }}
                    onBlur={(ev) => {
                        if (onBlur) onBlur(ev);
                        setFocus(false);
                    }}
                />
                {rightIcon && (
                    <div className={classes.rightIconContainer}>
                        <Button onClick={onClickRightIcon} typeButton="link">
                            <Icon iconName={rightIcon} />
                        </Button>
                    </div>
                )}
            </div>
            {error && <span className={classes.error}>{error}</span>}
        </label>
    );
};
