import React from 'react';
import styles from './styles';
import LogoPng from '../../assets/images/logo.svg';
import { Icon } from '../Icon';
import Button from '../Button';

interface HeaderMobileProps {
    onMenuClick: () => void;
}

const HeaderMobile = ({ onMenuClick }: HeaderMobileProps) => {
    const classes = styles();

    return (
        <header className={classes.container}>
            <Button onClick={onMenuClick} typeButton="link">
                <Icon
                    customStyles={classes.iconContainer}
                    iconName="icon-menu"
                />
            </Button>
            <div className={classes.logoContainer}>
                <img src={LogoPng} alt="Logomarca do Bagy Pay" />
            </div>
        </header>
    );
};

export default HeaderMobile;
