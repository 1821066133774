/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import { BagyPayThemeType } from '../../styles';
import CircleLoading from '../CircleLoading';
import { Icon } from '../Icon';
import styles from './styles';
import {
    RenderComponentParams,
    TableHeaderProps,
    TableProps,
    TableRowData,
} from './types';

const orderCols = (
    { order: orderA = 0 }: Partial<TableHeaderProps>,
    { order: orderB = 0 }: Partial<TableHeaderProps>,
) => {
    if (orderA > orderB) return 1;
    if (orderA < orderB) return -1;
    return 0;
};

const Table = ({
    isLoading,
    headers,
    idxColsHideOnMobile = [],
    idxColsHideOnDesktop = [],
    listData,
    hideArrowRight,
    dataConfig = {},
    onClickRow,
}: TableProps) => {
    const theme = useTheme<BagyPayThemeType>();
    const classes = styles({
        theme: {
            ...theme,
            hideArrowRight,
        },
    });
    const [rows, setRows] = useState<TableRowData[]>([]);
    const [headerCols, setHeaderCols] = useState<TableHeaderProps[]>([]);

    const buildRows = useCallback(() => {
        const rowsBuilt: TableRowData[] = listData.map((data) => ({
            cols: Object.keys(data)
                .map((key) => ({
                    key,
                    data: data[key],
                    order: headers[key] ? headers[key].order : 0,
                }))
                .sort(orderCols)
                .map(({ data: value, key }) => {
                    if (key in dataConfig) {
                        return { ...dataConfig[key], value };
                    }
                    return {
                        value,
                    };
                }),
            data,
        }));
        setRows(rowsBuilt);
    }, [listData, dataConfig, headers]);

    const buildHeader = useCallback(() => {
        const headerBuilt: TableHeaderProps[] = Object.keys(headers)
            .map((key) => headers[key])
            .sort(orderCols);
        setHeaderCols(headerBuilt);
    }, [headers]);

    useEffect(() => {
        buildRows();
        buildHeader();
    }, [buildRows]);

    const getCol = useCallback(
        (
            index: number,
            rowData: any,
            value?: string,
            renderComponent?: (
                data: RenderComponentParams,
            ) => React.ReactElement,
        ) => {
            const tdClassesOnMobile = idxColsHideOnMobile.includes(index)
                ? '-hide-mobile'
                : '';
            const tdClassesOnDesktop = idxColsHideOnDesktop.includes(index)
                ? '-hide-desktop'
                : '';

            return (
                <td
                    key={index}
                    className={`${tdClassesOnMobile} ${tdClassesOnDesktop}`}
                >
                    {renderComponent
                        ? renderComponent({ value, rowData })
                        : value}
                </td>
            );
        },
        [idxColsHideOnMobile],
    );

    return (
        <table className={classes.container}>
            <thead>
                <tr className={`${classes.row} ${classes.headerRow}`}>
                    {headerCols.map(({ title }, index) => (
                        <th
                            key={title}
                            className={`${
                                idxColsHideOnMobile.includes(index)
                                    ? '-hide-mobile'
                                    : ''
                            } ${
                                idxColsHideOnDesktop.includes(index)
                                    ? '-hide-desktop'
                                    : ''
                            }`}
                        >
                            {title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isLoading && !rows.length && (
                    <tr className={classes.loading}>
                        <td>
                            <CircleLoading />
                        </td>
                    </tr>
                )}
                {rows.map(({ cols, data }) => (
                    <tr
                        key={data.transactionId}
                        className={classes.row}
                        onClick={() => onClickRow && onClickRow(data)}
                    >
                        {cols.map(({ value, renderComponent }, index) =>
                            getCol(index, data, value, renderComponent),
                        )}
                        {onClickRow && (
                            <td className={classes.iconClickRow}>
                                <Icon iconName="icon-chevron-right" />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
