import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { loginValidationSchema } from '../../utils/hooks/loginValidationSchema';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import styles from './styles';
import LogoPng from '../../assets/images/logo.svg';
import { login } from '../../services/auth/login';
import { showToastWarning } from '../../components/Toast';
import useAuthHook from '../../utils/hooks/auth.hooks';
import { RootStateType } from '../../redux';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

export const Login = () => {
    const { refreshToken } = useSelector((state: RootStateType) => state.auth);
    const { signIn } = useAuthHook();
    const [loading, setLoading] = useState(false);
    const classes = styles();
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const submitLoginForm = async ({
        email,
        password,
    }: {
        email: string;
        password: string;
    }) => {
        try {
            setLoading(true);
            const data = await login(email, password);
            await signIn(data);
        } catch (error: any) {
            showErrorAsToast({
                error,
                message: 'E-mail ou senha incorreto. Tente novamente.',
                title: 'Desculpe',
                showToast: showToastWarning,
            });
        } finally {
            setLoading(false);
        }
    };

    const { values, errors, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                email: '',
                password: '',
            },
            onSubmit: submitLoginForm,
            validationSchema: loginValidationSchema,
        });

    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <img src={LogoPng} alt="" />
            </div>

            <div className={classes.form}>
                <form onSubmit={handleSubmit}>
                    <h1>Bem-vindo!</h1>
                    <div className={classes.inputForm}>
                        <Input
                            label="Seu e-mail"
                            name="email"
                            placeholder="Digite seu e-mail aqui"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors?.email}
                        />
                    </div>
                    <div className={classes.inputForm}>
                        <Input
                            name="password"
                            label="Sua senha"
                            placeholder="Digite sua senha aqui"
                            className={classes.inputPassword}
                            type={passwordShown ? 'text' : 'password'}
                            rightIcon={
                                passwordShown
                                    ? 'icon-eye-open'
                                    : 'icon-eye-closed'
                            }
                            onClickRightIcon={() => {
                                togglePassword();
                            }}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors?.password}
                        />
                    </div>
                    {refreshToken && (
                        <p className={classes.tokenExpiredMessage}>
                            Sua sessão expirou, faça o login novamente para
                            continar navegando pelo painel.
                        </p>
                    )}
                    <div className={classes.buttonForm}>
                        <div className={classes.login}>
                            <Button
                                type="submit"
                                title={loading ? 'Aguarde ...' : 'Fazer login'}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <footer className={classes.footer}>2022 © BagyPay</footer>
        </div>
    );
};
