import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'tableContainer'
    | 'verticalStatus'
    | 'statusTable'
    | 'statusWaiting'
    | 'statusCancelled'
    | 'statusSuccess'
    | 'buttonTable'
    | 'verticalStatusWaiting'
    | 'verticalStatusSuccess'
    | 'verticalStatusCancelled'
    | 'loading';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            position: 'relative',
            height: '100%',
            '& table row': {
                marginBottom: 0,
            },
        },
        verticalStatus: {
            position: 'absolute',
            left: 0,
            bottom: '0.5%',
            width: 8,
            height: '100%',

            [theme.breakpoints.desktop]: {
                display: 'none',
            },
        },
        verticalStatusCancelled: {
            backgroundColor: theme.colors.textSecondaryNeutral,
        },
        verticalStatusSuccess: {
            backgroundColor: theme.colors.backgroundGreen,
        },
        verticalStatusWaiting: {
            backgroundColor: theme.colors.backgroundOrange,
        },
        statusTable: {
            fontSize: 12,
            padding: '4px 8px',
            borderRadius: 8,
            display: 'inline-block',
            [theme.breakpoints.desktop]: {
                fontSize: 14,
            },
        },
        statusWaiting: {
            color: theme.colors.backgroundOrange,
            border: `1px solid ${theme.colors.backgroundOrange}`,
        },
        statusCancelled: {
            color: theme.colors.backgroundRed,
            border: `1px solid ${theme.colors.backgroundRed}`,
        },
        statusSuccess: {
            color: theme.colors.backgroundGreen,
            border: `1px solid ${theme.colors.backgroundGreen}`,
        },
        tableContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            [theme.breakpoints.desktop]: {
                '& table thead tr': {
                    marginTop: 24,
                    padding: '16px 24px',
                },
            },
            '& table tr': {
                borderRadius: 8,
                marginBottom: 0,
            },
        },
        buttonTable: {
            border: `1px solid ${theme.colors.textSecondaryNeutral}`,
            color: theme.colors.textSecondaryNeutral,
            borderRadius: 8,
            marginRight: 8,
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
            border: 'none',
        },
    }),
);

export default styles;
