import * as Yup from 'yup';

export const bankAccountValidationSchema = Yup.object({
    agencyBank: Yup.string()
        .required('Este campo é obrigatório')
        .typeError('Este campo aceita apenas números'),
    bankCode: Yup.number()
        .required('Este campo é obrigatório')
        .typeError('Este campo aceita apenas números'),
    numberAccount: Yup.string()
        .required('Este campo é obrigatório')
        .typeError('Este campo aceita apenas números'),
    typeAccount: Yup.string().required('Este campo é obrigatório'),
});
