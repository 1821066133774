/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { RootStateType } from '../../redux';
import env from '../../constants';

import Collapsable from '../../components/Collapsable';
import ContentHeader from '../../components/ContentHeader';
import { Icon } from '../../components/Icon';
import { Input } from '../../components/Input';
import styles from './styles';
import EmailContactButton from '../../components/EmailContactButton';
import { getFAQv2 } from '../../services/FAQ/getFAQv2';
import CircleLoading from '../../components/CircleLoading';
import { searchFAQ } from '../../services/FAQ/searchFAQ';
import useDebounce from '../../utils/hooks/useDebounce';

const Help = () => {
    const classes = styles();
    const auth = useSelector((state: RootStateType) => state.auth);
    const [FAQData, setFAQData] = useState([]);
    const { blipId } = auth;
    const getBlipURI = () => {
        const params = qs.stringify({ storeId: blipId, newDb: true });

        return `${env.CHAT_BLIP}?${params}`;
    };
    const [isLoading, setIsLoading] = useState(true);
    const [textToFilter, setTextToFilter] = useState('');
    const loadFAQ = useCallback(async () => {
        setIsLoading(true);
        const FAQ = await getFAQv2();

        setIsLoading(false);
        setFAQData(FAQ);
    }, []);

    useEffect(() => {
        loadFAQ();
    }, [loadFAQ]);

    const search = async () => {
        setIsLoading(true);
        const FAQ = await searchFAQ(textToFilter);
        setIsLoading(false);
        setFAQData(FAQ);
        setIsLoading(false);
    };

    const searchWord = useDebounce(textToFilter, 800);

    useEffect(() => {
        search();
    }, [searchWord]);

    return (
        <div className={classes.container}>
            <ContentHeader iconName="icon-people-support" title="Ajuda" />
            <Input
                name="FAQInput"
                placeholder="O que você procura hoje?"
                rightIcon="icon-search"
                onChange={(value) => {
                    setTextToFilter(value.target.value);
                }}
                className={classes.inputFind}
            />
            <h1 className={classes.headerFAQ}>Dúvidas frequentes</h1>
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && (
                <div className={classes.containerFAQ}>
                    {FAQData.map(({ question, answer, tutorialUrl }) => {
                        const answerString = answer as string;
                        const paragraphs = answerString.split('\n\n');
                        return (
                            <Collapsable
                                title={question}
                                tutorialUrl={tutorialUrl}
                                key={question}
                            >
                                {paragraphs.map((element) => (
                                    <p>
                                        {element}
                                        <br />
                                        <br />
                                    </p>
                                ))}
                            </Collapsable>
                        );
                    })}
                </div>
            )}
            <div className={classes.contactButtton}>
                <div className={classes.emailContainer}>
                    <EmailContactButton mailto="suportebagy@blip.ai" />
                </div>
                {blipId && (
                    <a
                        href={getBlipURI()}
                        className={classes.supportButton}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Icon iconName="icon-head-phone" />
                        Fale conosco
                    </a>
                )}
            </div>
        </div>
    );
};

export default Help;
