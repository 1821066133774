import { LOGIN } from '../graphql/mutations';
import { callPost } from '../graphql';

export const login = async (email: string, password: string) => {
    const { data: loginAxiosData } = await callPost(LOGIN, { email, password });
    const {
        data: { login: loginData },
    } = loginAxiosData;

    return loginData;
};
