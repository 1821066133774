import { CANCEL_TRANSACTION } from '../graphql/mutations';
import { callPost } from '../graphql';

export const cancelTransaction = async (transactionId: number) => {
    const { data: cancelTransactionAxiosData } = await callPost(
        CANCEL_TRANSACTION,
        { transactionId },
    );
    const { data } = cancelTransactionAxiosData;

    return data.bagyPayCancelTransaction;
};
