import React from 'react';
import Table from '../../components/Table';
import VisaPng from '../../assets/images/visa.png';
import styles from './styles';
import {
    DataConfigType,
    TableHeader,
    TableProps,
} from '../../components/Table/types';
import Button from '../../components/Button';

export type DataType = {
    transactionId: string;
    buyerName: string;
    amount: number;
    method: string;
    status: string;
    date: string;
};

const TableExampleTwo = () => {
    const classes = styles();

    const dataMock: DataType[] = [
        {
            amount: 180.95,
            buyerName: 'José Batista Amado',
            transactionId: '43535346',
            method: 'visa',
            status: 'Aguardando Pagamento',
            date: '22/07/2022',
        },
        {
            amount: 180.95,
            buyerName: 'José Batista Amado',
            transactionId: '43535346',
            method: 'pix',
            status: 'Pagamento Aprovado',
            date: '22/07/2022',
        },
        {
            amount: 180.95,
            buyerName: 'José Batista Amado',
            transactionId: '43535346',
            method: 'visa',
            status: 'Cancelado',
            date: '22/07/2022',
        },
    ];

    const dataMockExtended = dataMock.map((data) => ({
        ...data,
        detailAction: 'Detalhes',
    }));

    const headers: TableHeader = {
        transactionId: { title: 'Transação', order: 0 },
        buyerName: { title: 'Comprador', order: 1 },
        amount: { title: 'Valor Total', order: 2 },
        method: { title: 'Método', order: 3 },
        status: { title: 'Status', order: 4 },
        date: { title: 'Data', order: 5 },
        detailAction: { title: 'Ações', order: 6 },
    };
    const idxColsHideOnMobile: number[] = [6, 5, 4, 3];

    const dataConfig: DataConfigType<DataType> = {
        status: {
            renderComponent: ({ value }) => (
                <div
                    style={{
                        padding: 5,
                        border: '1px solid red',
                        borderRadius: 5,
                    }}
                >
                    {value}
                </div>
            ),
        },
        method: {
            renderComponent: () => (
                <img src={VisaPng} alt="bandeira do cartão de credito" />
            ),
        },
        detailAction: {
            renderComponent: ({ value }) => <Button title={value} />,
        },

        transactionId: {
            renderComponent: ({ value, rowData }) => {
                const { status } = rowData;
                return (
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: '5%',
                                borderRadius: 5,
                                backgroundColor:
                                    status === 'Pagamento Aprovado'
                                        ? 'red'
                                        : 'yellow',
                                width: 5,
                                height: '90%',
                            }}
                        />
                        <div>{value}</div>
                    </div>
                );
            },
        },
    };

    const tableProps: TableProps<DataType> = {
        headers,
        idxColsHideOnMobile,
        listData: dataMockExtended,
        dataConfig,
        onClickRow: (data) => console.log(data),
    };

    return (
        <div className={classes.container}>
            <Table {...tableProps} />
        </div>
    );
};

export default TableExampleTwo;
