export default {
    API_URL: process.env.REACT_APP_API_URL,
    CHAT_BLIP: process.env.REACT_APP_CHATBOT_URL,
};

export const METHOD_PAYMENT_BAGY_PAY = {
    billet: 6,
};

export const TRANSACTION_STATUS = {
    WAITING_PAYMENT: 'waiting_payment',
    MONITORING: 'monitoring',
    CANCELED: 'canceled',
    APPROVED: 'approved',
    FAILED: 'failed',
    CONTESTATION: 'contestation',
    CHARGEBACK: 'chargeback',
};

export const TRANSACTION_STATUS_LABEL = {
    [TRANSACTION_STATUS.WAITING_PAYMENT]: 'Aguardando pagamento',
    [TRANSACTION_STATUS.MONITORING]: 'Em monitoramento',
    [TRANSACTION_STATUS.CANCELED]: 'Cancelado',
    [TRANSACTION_STATUS.APPROVED]: 'Pagamento aprovado',
    [TRANSACTION_STATUS.FAILED]: 'Pagamento recusado',
    [TRANSACTION_STATUS.CONTESTATION]: 'Em contestação',
    [TRANSACTION_STATUS.CHARGEBACK]: 'Em contestação',
};
export const BAGY_PAY_MESSAGE = {
    SUCCESS: 'success',
};

export const TRANSACTION_STATUS_LABEL_MOBILE = {
    [TRANSACTION_STATUS.WAITING_PAYMENT]: 'Aguardando',
    [TRANSACTION_STATUS.MONITORING]: 'Monitoramento',
    [TRANSACTION_STATUS.CANCELED]: 'Cancelado',
    [TRANSACTION_STATUS.APPROVED]: 'Aprovado',
    [TRANSACTION_STATUS.FAILED]: 'Recusado',
    [TRANSACTION_STATUS.CONTESTATION]: 'Contestação',
    [TRANSACTION_STATUS.CHARGEBACK]: 'Contestação',
};

export const TRANSACTION_STATUS_COLOR = {
    [TRANSACTION_STATUS.WAITING_PAYMENT]: '#FFBD02',
    [TRANSACTION_STATUS.MONITORING]: '#FFBD02',
    [TRANSACTION_STATUS.CANCELED]: '#8D8989',
    [TRANSACTION_STATUS.APPROVED]: '#0ACD94',
    [TRANSACTION_STATUS.FAILED]: '#CF2323',
    [TRANSACTION_STATUS.CONTESTATION]: '#CF2323',
    [TRANSACTION_STATUS.CHARGEBACK]: '#CF2323',
};

export const BAGY_PAY_ACCOUNT_TYPE = {
    business: 'business',
    personal: 'personal',
};

export const BAGY_PAY_ACCOUNT_TYPE_LABEL = {
    [BAGY_PAY_ACCOUNT_TYPE.business]: 'Empresarial',
    [BAGY_PAY_ACCOUNT_TYPE.personal]: 'Pessoal',
};
