/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Icon } from '../Icon';
import { IconNames } from '../Icon/icon-names.type';
import styles from './styles';

export interface BoxGrayProps extends React.HTMLAttributes<HTMLDivElement> {
    textInformation?: string;
    spanTextOne: string;
    spanTextTwo?: string;
    iconName: IconNames;
    link?: string;
    textLink?: string;
}
const BoxInformation = (props: BoxGrayProps) => {
    const {
        textInformation,
        spanTextOne,
        spanTextTwo,
        iconName,
        link,
        textLink,
    } = props;
    const classes = styles();

    return (
        <div className={classes.container}>
            <div className={classes.iconContainer}>
                <Icon iconName={iconName} />
            </div>
            <div className={classes.informationContainer}>
                <span className={classes.spanOne}>{spanTextOne}</span>
                <h1>{textInformation}</h1>
                {spanTextTwo && (
                    <span className={classes.spanTwo}>{spanTextTwo}</span>
                )}
                {link && (
                    <div
                        className={`${classes.linkStyles} ${classes.linkTablet}`}
                    >
                        <a href={`${link}`}>{textLink}</a>
                    </div>
                )}
            </div>
            {link && (
                <div
                    className={`${classes.linkContainer} ${classes.linkStyles}`}
                >
                    <a href={`${link}`}>{textLink}</a>
                </div>
            )}
        </div>
    );
};
export default BoxInformation;
