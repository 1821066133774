import React from 'react';
import BoxGray from '../BoxGray';
import { Icon } from '../Icon';
import styles from './styles';

interface BankInformationProps {
    bank: string;
    typeaccount: string;
    bankBranch: string;
    accountNumber: string;
}
const BankInformation = (props: BankInformationProps) => {
    const { bank, typeaccount, bankBranch, accountNumber } = props;
    const classes = styles();
    return (
        <BoxGray>
            <div>
                <div className={classes.header}>
                    <h1>{bank}</h1>
                    <Icon iconName="icon-check" />
                </div>

                <table className={classes.bankContainer}>
                    <tr>
                        <th>Tipo de conta</th>
                        <td>{typeaccount}</td>
                    </tr>
                    <tr>
                        <th>Agência</th>
                        <td>{bankBranch}</td>
                    </tr>
                    <tr>
                        <th>Número da conta</th>
                        <td>{accountNumber}</td>
                    </tr>
                </table>
            </div>
        </BoxGray>
    );
};
export default BankInformation;
