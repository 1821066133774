/* eslint-disable @typescript-eslint/no-unused-vars */
import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        '@keyframes lds-ring': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
        container: {
            '& .lds-ring': {
                display: 'inline-block',
                position: 'relative',
                width: 80,
                height: 80,
            },
            '& .lds-ring div': {
                boxSizing: 'border-box',
                display: 'block',
                position: 'absolute',
                width: 64,
                height: 64,
                margin: 8,
                border: `8px solid ${theme.colors.primaryPure}`,
                borderRadius: '50%',
                animation:
                    '$lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
                borderColor: `${theme.colors.primaryPure} transparent transparent transparent`,
            },
            '& .lds-ring div:nth-child(1)': { animationDelay: '-0.45s' },
            '& .lds-ring div:nth-child(2)': { animationDelay: '-0.3s' },
            '& .lds-ring div:nth-child(3)': { animationDelay: '-0.15s' },
        },
    }),
);

export default styles;
