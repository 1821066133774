import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux';
import { GlobalModalType, hideModal } from '../../../redux/slices';
import Button from '../../Button';
import styles from './styles';

export const DialogModal = () => {
    const classes = styles();
    const dispatch = useDispatch();
    const { data } = useSelector<RootStateType, GlobalModalType>(
        (state) => state.globalModal,
    );

    const { message, title, onClick, buttonText } = data;
    return (
        <div className={classes.modalContent}>
            <h1 className={classes.modalTitle}>{title}</h1>
            <p className={classes.modalTextContent}>{message}</p>
            <Button
                title={buttonText || 'Prosseguir'}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                    dispatch(hideModal());
                }}
            />
        </div>
    );
};
