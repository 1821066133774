import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../../styles';

type RuleNames = 'informationLine' | 'informationHeader' | 'informationData';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        informationLine: {
            marginTop: 16,
        },
        informationHeader: {
            fontWeight: 'bold',
            marginRight: 8,
            whiteSpace: 'nowrap',
        },
        informationData: {
            color: theme.colors.textNeutralPlaceholder,
            wordBreak: 'break-word',
            '& a': {
                color: theme.colors.textNeutralPlaceholder,
            },
        },
    }),
);

export default styles;
