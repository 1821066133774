/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux';
import { GlobalModalType } from '../../redux/slices';
import { ConfirmModal } from './ConfirmModal';
import { DialogModal } from './DialogModal';
import styles from './styles';

export const GlobalModal = () => {
    const classes = styles();
    const [showContainer, setShowContainer] = useState<boolean>();
    const { modalType, data, isOpen } = useSelector<
        RootStateType,
        GlobalModalType
    >((state) => state.globalModal);

    const { message, title, onClick } = data;

    useEffect(() => {
        setTimeout(() => {
            setShowContainer(isOpen);
        }, 200);
    }, [isOpen]);

    const modals = {
        DIALOG_MODAL: <DialogModal />,
        CONFIRM_MODAL: <ConfirmModal />,
    };

    return (
        <div
            style={{
                display: isOpen ? 'flex' : 'none',
            }}
            className={`${classes.container} ${showContainer ? '-isOpen' : ''}`}
        >
            <div className={classes.modalContainer}>{modals[modalType]}</div>
        </div>
    );
};
