import { callPost } from '../graphql';
import { BAGY_PAY_VINDI_GET_WITHDRAWALS } from '../graphql/query';
import { GetWithdrawalsResultType } from './getWithdrawals.type';

export const getWithdrawals = async ({
    limit = 10,
    offset = 0,
}): Promise<GetWithdrawalsResultType> => {
    const { data: axiosData } = await callPost(BAGY_PAY_VINDI_GET_WITHDRAWALS, {
        limit,
        offset,
    });
    const { data } = axiosData;
    return data.bagyPayVindiGetWithdrawals;
};
