import { createUseStyles } from 'react-jss';

type RuleNames =
    | 'container'
    | 'contentContainer'
    | 'buttonContainer'
    | 'content'
    | 'loading';

const styles = createUseStyles<RuleNames, unknown>({
    container: {
        padding: 16,
        fontSize: 18,
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
    },
    contentContainer: {
        margin: 'auto',
        maxWidth: 720,
    },
    content: {
        margin: 16,
    },
    buttonContainer: {
        margin: '24px auto',
        maxWidth: 192,
        textAlign: 'center',
    },
});
export default styles;
