import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'logoContainer'
    | 'navContainer'
    | 'navItem'
    | 'accountContainer'
    | 'logoutButton';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        logoContainer: {
            marginBottom: 24,
            marginTop: 14,
            '& img': {
                width: 115,
            },
            [theme.breakpoints.desktop]: {
                '& img': {
                    width: 150,
                },
            },
        },
        navContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '24px 0',
            borderTop: `1px solid ${theme.colors.borderColor}`,
            borderBottom: `1px solid ${theme.colors.borderColor}`,
        },
        navItem: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
            transform: 'none',
            textTransform: 'none',
            textDecoration: 'none',
            fontSize: 14,
            color: `${theme.colors.textNeutral}`,
            '& i': {
                marginRight: 16,
                fontSize: 18,
                color: `${theme.colors.primaryPure}`,
            },
        },
        logoutButton: {
            flexDirection: 'column',
            color: `${theme.colors.textNeutral}`,
            fontWeight: 400,
            padding: '10px 0',
            backgroundColor: 'transparent',
            '& i': {
                marginRight: 16,
                fontSize: 18,
                color: `${theme.colors.primaryPure}`,
            },
        },
        accountContainer: {
            paddingTop: 21,
        },
    }),
);

export default styles;
