import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../../styles';

type RuleNames = 'modalContent' | 'modalTitle' | 'modalTextContent';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(() => ({
    modalContent: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        backgroundColor: '#fff',
        maxWidth: 476,
        borderRadius: 16,
    },
    modalTitle: {
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'center',
    },
    modalTextContent: {
        margin: '32px 0',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 1.22,
    },
}));

export default styles;
