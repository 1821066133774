import { loader } from 'graphql.macro';

const GET_ACCOUNT = loader('./getAccount.gql');
const GET_ADDRESS_ACCOUNT = loader('./bagyPayVindiGetAddressAccount.gql');
const GET_ACCOUNT_FOR_LOGIN = loader('./getAccountForLogin.gql');
const ME = loader('./me.gql');
const BAGY_PAY_VINDI_GET_SALES = loader('./bagyPayVindiGetSales.gql');
const BAGY_PAY_VINDI_GET_BALANCE = loader('./bagyPayVindiGetBalance.gql');
const BAGY_PAY_VINDI_GET_WITHDRAWALS = loader(
    './bagyPayVindiGetWithdrawals.gql',
);

const BAGY_PAY_VINDI_GET_BANK_ACCOUNT = loader(
    './bagyPayVindiGetBankAccount.gql',
);
const BAGY_PAY_VINDI_SEARCH_BANK_ACCOUNT = loader(
    './bagyPayVindiSearchBankAccount.gql',
);
const FAQ = loader('./getFAQv2.gql');
const SEARCH_FAQ = loader('./searchFAQ.gql');

const BAGY_PAY_VINDI_GET_TRANSACTION = loader(
    './bagyPayVindiGetTransaction.gql',
);

const BAGY_PAY_VINDI_GET_SELLER_SPLITS = loader(
    './bagyPayVindiGetSellerSplits.gql',
);

export {
    GET_ADDRESS_ACCOUNT,
    GET_ACCOUNT,
    BAGY_PAY_VINDI_GET_SALES,
    ME,
    BAGY_PAY_VINDI_GET_BALANCE,
    BAGY_PAY_VINDI_GET_WITHDRAWALS,
    BAGY_PAY_VINDI_GET_BANK_ACCOUNT,
    BAGY_PAY_VINDI_GET_SELLER_SPLITS,
    GET_ACCOUNT_FOR_LOGIN,
    BAGY_PAY_VINDI_GET_TRANSACTION,
    FAQ,
    SEARCH_FAQ,
    BAGY_PAY_VINDI_SEARCH_BANK_ACCOUNT,
};
