import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'icon' | 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            [theme.breakpoints.desktop]: {
                flexDirection: 'row',
            },
        },
        icon: {
            fontSize: 45,
        },
    }),
);

export default styles;
