import React from 'react';
import { Icon } from '../Icon';
import styles from './styles';

interface AccountMenuItemProps {
    name: string;
    email: string;
}

const AccountMenuItem = ({ name, email }: AccountMenuItemProps) => {
    const classes = styles();

    return (
        <div className={classes.container}>
            <Icon customStyles={classes.iconPeople} iconName="icon-people" />
            <div className={classes.userInfo}>
                <span>{name}</span>
                <span>{email}</span>
            </div>
        </div>
    );
};

export default AccountMenuItem;
