import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'header'
    | 'titleContainer'
    | 'chevronDownIcon'
    | 'contentContainer'
    | 'title';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 12px',

            backgroundColor: theme.colors.backgroundGray,
            border: `1px solid ${theme.colors.borderColor}`,
            '&:first-of-type': {
                borderRadius: '8px 8px 0px 0px',
            },
            '&:last-of-type': {
                borderRadius: '0px 0px 8px 8px',
            },
            [theme.breakpoints.desktop]: {
                padding: 24,
            },
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
        },
        titleContainer: {
            fontSize: 14,
            color: theme.colors.textNeutralPlaceholder,
            display: 'flex',

            justifyContent: 'flex-start',
            alignItems: 'center',
            fontWeight: 'bold',

            [theme.breakpoints.desktop]: {
                fontSize: 16,
            },
        },
        chevronDownIcon: {
            transition: 'transform .4s ease-in-out',
            transform: 'rotate(0deg)',
            '&.-isOpen': {
                transform: 'rotate(180deg)',
            },
            fontSize: 8,
            color: theme.colors.textSecondaryNeutral,
        },
        contentContainer: {
            fontSize: 14,
            color: theme.colors.textNeutral,
            alignItems: 'center',
            maxHeight: 0,
            overflow: 'hidden',
            transition: 'max-height .4s ease-in-out',
            '&.-isOpen': {
                maxHeight: 'none',
                padding: '16px 0 16px 0',
                [theme.breakpoints.desktop]: {
                    padding: '24px 0 24px 0',
                },
            },
        },
        title: {
            margin: '0',
        },
    }),
);

export default styles;
