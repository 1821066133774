import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'indicesPage'
    | 'buttonBox'
    | 'buttonLink'
    | 'currentPage'
    | 'registerDesktop'
    | 'footerRegister';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            color: theme.colors.backgroundPlaceholderNeutral,
            textAlign: 'center',
            fontSize: 14,
        },
        registerDesktop: {
            padding: 8,
            display: 'none',
            [theme.breakpoints.desktop]: {
                display: 'inline',
            },
        },
        indicesPage: {
            display: 'flex',
            justifyContent: 'center',
            margin: '24px auto',
            [theme.breakpoints.desktop]: {
                justifyContent: 'space-between',
            },
        },
        buttonBox: {
            display: 'flex',
            gap: 8,
        },
        buttonLink: {
            color: theme.colors.backgroundPlaceholderNeutral,
            border: `1px solid ${theme.colors.backgroundPlaceholderNeutral}`,
            borderRadius: 4,
            minWidth: 32,
        },
        currentPage: {
            textDecoration: 'none',
            color: theme.colors.white,
            backgroundColor: theme.colors.backgroundPlaceholderNeutral,
        },
        footerRegister: {
            '& p': {
                padding: 8,
            },
            [theme.breakpoints.desktop]: {
                display: 'none',
            },
        },
    }),
);
export default styles;
