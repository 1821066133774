import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { GRAPHQL_MAX_INT } from 'graphql';
import BalanceWithdrawalBox from '../../components/BalanceWithdrawalBox';
import BankInformation from '../../components/BankInformation';
import Button from '../../components/Button';
import ContentHeader from '../../components/ContentHeader';
import WithdrawalInformation from '../../components/WithdrawalInformation';
import styles from './styles';
import { getWithdrawals } from '../../services/withdrawals/getWithdrawls';
import CircleLoading from '../../components/CircleLoading';
import { formatCurrency } from '../../utils/formatter';

type WithdrawDataType = {
    amount: number;
    fee: number;
    bankName: string;
    bankAccount: string;
    bankAgency: string;
    bankResource: string;
    status: string;
    requestDate: string;
};

type WithdrawStatusType = {
    [k: string]: string;
};

const DetailsWithdrawals = () => {
    const classes = styles();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const widthdrawStatus: WithdrawStatusType = {
        in_analysis: 'Em análise',
        processed: 'Processado',
        refused: 'Recusado',
        canceled: 'Cancelado',
    };
    const description: WithdrawStatusType = {
        in_analysis:
            'Seu saque foi solicitado com sucesso e agora está passando por uma análise interna.',
        processed: 'Seu saque foi realizado com sucesso.',
        refused: 'Seu saque foi recusado.',
        canceled: 'Seu saque foi cancelado.',
    };
    const [data, setData] = useState<WithdrawDataType>({
        amount: 0,
        fee: 0,
        bankName: '',
        bankAccount: '',
        bankAgency: '',
        bankResource: '',
        status: '',
        requestDate: '',
    });
    const loadWithdrawls = async () => {
        setIsLoading(true);
        const { withdrawals } = await getWithdrawals({
            limit: GRAPHQL_MAX_INT,
            offset: 0,
        });
        const currentWithdraw = withdrawals.find(
            (withdraw) =>
                withdraw.id.toString() ===
                window.location.pathname.split('/').pop(),
        );
        if (currentWithdraw) {
            const { amount, status, bankAccount, requestDate, fee } =
                currentWithdraw;
            const { account, agency, bank, resource } = bankAccount;
            const { name } = bank;
            setData({
                amount,
                fee,
                bankName: name,
                bankAccount: account,
                bankAgency: agency,
                bankResource: resource,
                status,
                requestDate,
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadWithdrawls();
    }, []);

    return (
        <div className={classes.container}>
            <ContentHeader
                iconName="icon-hand-money"
                title="Retirada para conta bancária"
            />
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && (
                <div>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <WithdrawalInformation
                                numberWithdrawal={
                                    window.location.pathname.split('/').pop() ||
                                    ''
                                }
                                description={description[data.status]}
                            />
                        </div>
                        <div className={classes.content}>
                            <BalanceWithdrawalBox
                                title="Valor a receber"
                                valueWithdrawal={formatCurrency(data.amount)}
                                taxOfWithdrawal={formatCurrency(data.fee)}
                                statusOfWithdrawal={
                                    widthdrawStatus[data.status]
                                }
                                dateOfWithdrawal={moment(
                                    data.requestDate,
                                ).format('DD/MM/YYYY HH:mm')}
                            />
                        </div>
                        <div className={classes.content}>
                            <BankInformation
                                bank={data.bankName}
                                bankBranch={data.bankAgency}
                                accountNumber={data.bankAccount}
                                typeaccount="Conta corrente"
                            />
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        {/* TODO: Não temos endpoint para cancelar saque */}
                        {/* <Button title="Cancelar saque" typeButton="default" /> */}
                        <Button
                            title="< Voltar"
                            typeButton="link"
                            onClick={() => navigate(-1)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
export default DetailsWithdrawals;
