import { callPost } from '../graphql';
import { BAGY_PAY_VINDI_GET_TRANSACTION } from '../graphql/query';

export const getTransaction = async (tokenTransaction?: string) => {
    const { data: axiosData } = await callPost(BAGY_PAY_VINDI_GET_TRANSACTION, {
        tokenTransaction,
    });
    const { data } = axiosData;
    return data.bagyPayVindiGetTransaction;
};
