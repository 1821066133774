import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';
import { HideArrowType } from './types';

type RuleNames = 'container' | 'headerRow' | 'row' | 'iconClickRow' | 'loading';

type TableStyleProps = BagyPayThemeType & { hideArrowRight?: HideArrowType };

const styles = createUseStyles<RuleNames, unknown, TableStyleProps>(
    (theme) => ({
        container: {
            width: '100%',
            position: 'relative',
            height: '100%',
        },
        row: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-around',
            borderRadius: '0 8px 8px 0',
            padding: 8,
            fontSize: 12,
            marginTop: 12,
            color: theme.colors.textNeutral,
            backgroundColor: theme.colors.backgroundGray,
            border: `1px solid ${theme.colors.borderColor}`,
            [theme.breakpoints.desktop]: {
                padding: 14,
                fontSize: 14,
                marginTop: 16,
                borderBottom: `1px solid ${theme.colors.borderColor}`,
                borderLeft: `1px solid ${theme.colors.borderColor}`,
                borderRight: `1px solid ${theme.colors.borderColor}`,
                border: `1px solid ${theme.colors.borderColor}`,
                borderRadius: '0 8px 8px 0',

            },
            '& th, td': {
                flex: 1,
                margin: '0 16px 0 5px',
                '&.-hide-mobile': {
                    display: 'none',
                    [theme.breakpoints.desktop]: {
                        display: 'inline-block',
                    },
                },
                '&.-hide-desktop': {
                    [theme.breakpoints.desktop]: {
                        display: 'none !important',
                    },
                },
            },
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
            border: 'none !important',
        },
        headerRow: {
            fontWeight: 'bold',
            borderRadius: 8,
            textAlign: 'left',
            color: theme.colors.textNeutral,
            fontSize: 14,
            [theme.breakpoints.desktop]: {
                fontSize: 16,
            }

        },
        iconClickRow: {
            right: 14,
            top: 0,
            position: 'absolute',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.textNeutral,
            transform: 'translate(20px)',
            marginRight: 3,
            '& i': {
                fontSize: 10,
            },
            ...(theme.hideArrowRight
                ? {
                    [theme.breakpoints[theme.hideArrowRight.media]]: {
                        display: 'none',
                    },
                }
                : {}),
        },
    }),
);

export default styles;
