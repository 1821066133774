import React from 'react';
import Button from '../Button';
import styles from './styles';

interface PaginationProps {
    total: number;
    totalPerPage: number;
    currentPage: number;
    onChangePage: (val: number) => void;
}
const Pagination = (props: PaginationProps) => {
    const classes = styles();
    const DISTANCE_SIDE_PAGES = 2;

    const { total, totalPerPage, currentPage, onChangePage } = props;

    const totalPage = Math.ceil(total / totalPerPage);

    const hiddenPages = (value: number) =>
        value >= currentPage - DISTANCE_SIDE_PAGES &&
        value <= currentPage + DISTANCE_SIDE_PAGES;

    const pagesArray = Array.from(
        { length: totalPage },
        (_, i) => i + 1,
    ).filter(hiddenPages);

    const isShowPreviusButton = currentPage > DISTANCE_SIDE_PAGES;
    const isShowNextButton = totalPage - currentPage >= DISTANCE_SIDE_PAGES;

    const nextPage = () => {
        if (currentPage >= totalPage) return;
        onChangePage(currentPage + 1);
    };

    const previousPage = () => {
        if (currentPage <= 0) return;
        onChangePage(currentPage - 1);
    };

    return (
        <div className={classes.container}>
            <div className={classes.indicesPage}>
                <p className={classes.registerDesktop}>
                    Total de {total} registros encontrados
                </p>
                <div className={classes.buttonBox}>
                    {isShowPreviusButton && (
                        <Button
                            typeButton="link"
                            title="<"
                            onClick={previousPage}
                            styleWrapper={classes.buttonLink}
                        />
                    )}
                    {pagesArray.map((page) => {
                        const isCurrentPage = page === currentPage;
                        return (
                            <Button
                                key={page}
                                styleWrapper={`${classes.buttonLink} ${
                                    isCurrentPage && classes.currentPage
                                }`}
                                onClick={() => onChangePage(page)}
                                typeButton="link"
                                title={`${page}`}
                            />
                        );
                    })}
                    {isShowNextButton && (
                        <Button
                            typeButton="link"
                            title=">"
                            onClick={nextPage}
                            styleWrapper={classes.buttonLink}
                        />
                    )}
                </div>
                <p className={classes.registerDesktop}>
                    Exibindo {totalPerPage} por página
                </p>
            </div>
            <div className={classes.footerRegister}>
                <p>Total de {total} registros encontrados</p>
                <p>Exibindo {totalPerPage} por página</p>
            </div>
        </div>
    );
};

export default Pagination;
