import { ErrorConstants } from './constants';

export class BaseError extends Error {
    errorType: ErrorConstants;

    isBagyPayPanelError = true;

    constructor(errorType: ErrorConstants, message: string) {
        super(message);
        this.errorType = errorType;
    }
}
