import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { Icon } from '../Icon';
import { IconNames } from '../Icon/icon-names.type';
import styles from './styles';

export interface ContentHeaderProps {
    iconName: IconNames;
    title: string;
    subtitle?: string;
    showBackButton?: boolean;
}

const ContentHeader = (props: ContentHeaderProps) => {
    const navigate = useNavigate();
    const { iconName, title, subtitle, showBackButton } = props;
    const classes = styles();
    return (
        <div>
            <div className={classes.container}>
                <Icon iconName={iconName} />
                <h1>{title}</h1>
                {showBackButton && (
                    <Button typeButton="link" onClick={() => navigate(-1)}>
                        <Icon iconName="icon-chevron-left" />
                        Voltar
                    </Button>
                )}
            </div>
            {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        </div>
    );
};

export default ContentHeader;
