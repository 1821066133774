import { CREATE_WITHDRAWAL } from '../graphql/mutations';
import { callPost } from '../graphql';

export const createWithdrawal = async (
    bankAccountId: string,
    cashValue: string,
) => {
    const { data: withdrawalAxiosData } = await callPost(CREATE_WITHDRAWAL, {
        bankAccountId,
        cashValue,
    });
    const { data } = withdrawalAxiosData;

    return data.bagyPayCreateWithdrawal;
};
