export const MEDIA_SIZES = {
    desktop: 1360,
    tablet: 768,
    mobile: 365,
};

export const bagyPayTheme = {
    colors: {
        primaryPure: '#FC3C8D',
        secondaryPure: '#2F2D2D',
        blueWaiting: '#8FA7E3',
        blueProcess: '#4A75E3',
        white: '#FFF',
        textSecondaryNeutral: '#8D8989',
        textNeutral: '#2F2D2D',
        textNeutralPlaceholder: '#59595F',
        backgroundPlaceholderNeutral: ' #A2A2A2',
        borderColor: ' #E5E5E5',
        backgroundSecondaryColor: '#FC88B9',
        backgroundGray: '#FAFAFA;',
        backgroundGreen: '#0ACD94',
        backgroundRed: '#CF2323',
        backgroundOrange: '#FFBD02',
    },
    fonts: {},
    spacings: {},
    breakpoints: {
        desktop: `@media (min-width: ${MEDIA_SIZES.desktop}px)`,
        tablet: `@media (min-width: ${MEDIA_SIZES.tablet}px)`,
        mobile: `@media (min-width: ${MEDIA_SIZES.mobile}px)`,
    },
    border: {
        radius: 12,
        radiusBig: 16,
    },
    layers: {
        base: 10,
        headerMobile: 20,
        overlay: 30,
        modal: 40,
        alwaysOnTop: 50,
    },
};

export type BagyPayThemeType = typeof bagyPayTheme;
export type MediaKeys = keyof typeof MEDIA_SIZES;
