import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import BoxInformation from '../../components/BoxInformation';
import Table from '../../components/Table';
import styles from './styles';
import {
    DataConfigType,
    TableHeader,
    TableProps,
} from '../../components/Table/types';
import Button from '../../components/Button';
import VisaPng from '../../assets/images/visa.png';
import Pagination from '../../components/Pagination';
import ContentHeader from '../../components/ContentHeader';
import { getWithdrawals } from '../../services/withdrawals/getWithdrawls';
import CircleLoading from '../../components/CircleLoading';
import { BalanceType } from '../../services/types/balancetype';
import { formatCurrency } from '../../utils/formatter';
import { getBalance } from '../../services/balance/getBalance';
import { showToastError } from '../../components/Toast';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

export type DataType = {
    transactionId: string;
    date: string;
    amount: number;
    status: string;
};

type WithdrawStatusType = {
    [k: string]: string;
};
const Withdrawals = () => {
    const classes = styles();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRegisters, setTotalRegisters] = useState(0);
    const totalRegistersPerPage = 5;
    const [withdrawalsData, setWithdrawalsData] = useState<DataType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [balance, setBalance] = useState<BalanceType>({
        resource: '',
        available: 0,
        pending: 0,
        transacted: 0,
    });
    const dataMockExtended = withdrawalsData.map((data) => ({
        ...data,
        detailAction: data.transactionId,
    }));

    const headers: TableHeader = {
        transactionId: { title: 'Solicitação', order: 0 },
        date: { title: 'Data', order: 1 },
        amount: { title: 'Valor Total', order: 2 },
        status: { title: 'Status', order: 3 },
        detailAction: { title: '', order: 4 },
    };
    const idxColsHideOnMobile: number[] = [1, 4];

    const loadWithdrawls = async () => {
        try {
            setIsLoading(true);
            const { withdrawals, total } = await getWithdrawals({
                limit: totalRegistersPerPage,
                offset: totalRegistersPerPage * (currentPage - 1),
            });
            setTotalRegisters(total);
            const accountBalance = await getBalance();
            const withdrawalsTable: DataType[] = withdrawals.map<DataType>(
                ({ dateStatus, status, amount, id }) => ({
                    transactionId: id,
                    date: dateStatus,
                    amount,
                    status,
                }),
            );
            setWithdrawalsData(withdrawalsTable);
            setIsLoading(false);
            setBalance(accountBalance);
        } catch (error: any) {
            showErrorAsToast({
                error,
                message: 'Ocorreu um ao carregar seus saques.',
                showToast: showToastError,
            });
        }
    };

    useEffect(() => {
        loadWithdrawls();
    }, [currentPage]);

    const dataConfig: DataConfigType<DataType> = {
        transactionId: {
            renderComponent: ({ value }) => <div>{value}</div>,
        },
        date: {
            renderComponent: ({ value }) => (
                <div>{moment(value).format('DD/MM/YYYY HH:mm')}</div>
            ),
        },
        status: {
            renderComponent: ({ value }) => {
                const widthdrawStatus: WithdrawStatusType = {
                    in_analysis: 'Em análise',
                    processed: 'Processado',
                    refused: 'Recusado',
                    canceled: 'Cancelado',
                };
                const status = widthdrawStatus[value as string];
                return (
                    <div className={classes.statusTableWaiting}>{status}</div>
                );
            },
        },
        amount: {
            renderComponent: ({ value }) => (
                <div>{formatCurrency(Number(value))}</div>
            ),
        },
        method: {
            renderComponent: () => (
                <img src={VisaPng} alt="bandeira do cartão de credito" />
            ),
        },
        detailAction: {
            renderComponent: () => (
                <div>
                    <Button
                        style={{
                            border: '1px solid #8D8989',
                            color: 'gray',
                            borderRadius: 8,
                            marginRight: 8,
                        }}
                        typeButton="link"
                        title="Detalhes"
                    />
                    {/* TODO: Não temos endpoint para cancelar saque */}
                </div>
            ),
        },
    };

    const tableProps: TableProps<DataType> = {
        headers,
        idxColsHideOnMobile,
        listData: dataMockExtended,
        dataConfig,
        onClickRow: (data) => {
            const { transactionId: id } = data;
            navigate(`${id}`);
        },
    };

    return (
        <div className={classes.container}>
            <ContentHeader
                iconName="icon-hand-money"
                title="Retirada para conta bancária"
            />
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && (
                <div>
                    <div className={classes.informationBoxes}>
                        <BoxInformation
                            className={classes.informationBoxes}
                            spanTextOne="SALDO DISPONÍVEL"
                            textInformation={formatCurrency(balance.available)}
                            iconName="icon-circle-money"
                            link="solicitar-saque"
                            textLink="Solicitar saque"
                        />
                        <BoxInformation
                            spanTextOne="saldo a receber"
                            textInformation={formatCurrency(balance.pending)}
                            iconName="icon-arrows-apposite"
                            // link="www"
                            // textLink="Ver"
                        />
                    </div>
                    <div className={classes.tableContainer}>
                        <Table
                            {...tableProps}
                            hideArrowRight={{
                                media: 'tablet',
                            }}
                        />
                        <Pagination
                            onChangePage={(value) => setCurrentPage(value)}
                            currentPage={currentPage}
                            totalPerPage={totalRegistersPerPage}
                            total={totalRegisters}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Withdrawals;
