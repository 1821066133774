import React, { useEffect, useState } from 'react';
import BoxGray from '../../components/BoxGray';
import CardBox from '../../components/CardBox';
import ContentHeader from '../../components/ContentHeader';
import styles from './styles';
import visa from '../../assets/images/visa.png';
import americanexpress from '../../assets/images/americanexpress.png';
import aura from '../../assets/images/aura.png';
import elo from '../../assets/images/elo.png';
import hiper from '../../assets/images/hiper.png';
import hipercard from '../../assets/images/hipercard.png';
import jcb from '../../assets/images/jcb.png';
import master from '../../assets/images/master.png';
import dinersclub from '../../assets/images/dinersclub.png';
import Button from '../../components/Button';
import { showToastError, showToastSuccess } from '../../components/Toast';
import { getSellerSplits } from '../../services/sellerSplits/getSellerSplits';
import { createSellerSplit } from '../../services/sellerSplits/createSellerSplits';
import SimpleLoading from '../../components/SimpleLoading';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

const SettingsInstallments = () => {
    const classes = styles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [installments, setInstallments] = useState(1);

    const loadInstallments = async () => {
        setIsLoading(true);
        const { maxSellerSplits } = await getSellerSplits();
        setInstallments(maxSellerSplits);
        setIsLoading(false);
    };

    const setNewInstallments = async (split: number) => {
        try {
            setIsLoading(true);
            const { maxSellerSplits } = await createSellerSplit(split);
            showToastSuccess({
                title: 'Sucesso! Seus dados foram salvos',
                message: '',
            });
            setInstallments(maxSellerSplits);
        } catch (error: any) {
            showErrorAsToast({
                error,
                message: 'Ocorreu um erro ao definir o parcelamento.',
                showToast: showToastError,
            });
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        loadInstallments();
    }, []);
    return (
        <>
            <ContentHeader
                iconName="icon-gear"
                title="Parcelamento de Vendas"
            />
            <BoxGray>
                <div>
                    <h1 className={classes.header}>Configurações</h1>
                    <div>
                        <h1 className={classes.headerInformation}>
                            1. Configurar parcelamento
                        </h1>
                        <p className={classes.textInformation}>
                            Selecione as condições de parcelamento que deseja
                            vender &quot;sem juros&quot;. Nestes casos não
                            haverá acréscimo de 1,99% a/m para o comprador.
                        </p>

                        <h1 className={classes.headerInformation}>
                            Disponível para:
                        </h1>
                        <div className={classes.cardContainer}>
                            <CardBox imgUrl={visa} />
                            <CardBox imgUrl={americanexpress} />
                            <CardBox imgUrl={aura} />
                            <CardBox imgUrl={elo} />
                            <CardBox imgUrl={hiper} />
                            <CardBox imgUrl={jcb} />
                            <CardBox imgUrl={master} />
                            <CardBox imgUrl={dinersclub} />
                            <CardBox imgUrl={hipercard} />
                        </div>
                        {isLoading && (
                            <div>
                                <SimpleLoading />
                            </div>
                        )}
                        {!isLoading && (
                            <div className={classes.setInstallments}>
                                <button
                                    className={`${classes.button} ${classes.downButton}`}
                                    onClick={() =>
                                        setInstallments(
                                            installments >= 1
                                                ? installments - 1
                                                : 0,
                                        )
                                    }
                                >
                                    -
                                </button>
                                <input
                                    disabled
                                    type="number"
                                    min="0"
                                    step="1"
                                    onChange={({ target }) =>
                                        setInstallments(+target.value)
                                    }
                                    value={installments}
                                />
                                <button
                                    className={`${classes.button} ${classes.upButton}`}
                                    onClick={() =>
                                        setInstallments(
                                            installments < 12
                                                ? installments + 1
                                                : 12,
                                        )
                                    }
                                >
                                    +
                                </button>
                            </div>
                        )}

                        <div>
                            <p className={classes.textInformation}>
                                Para as condições selecionadas, o valor
                                referente aos juros do parcelamento será
                                descontado do vendedor juntamente à retenção.
                            </p>
                        </div>
                    </div>
                </div>
            </BoxGray>
            <div className={classes.footer}>
                <Button
                    disabled={isLoading}
                    title="Salvar configurações"
                    onClick={() => {
                        setNewInstallments(installments);
                    }}
                />
            </div>
        </>
    );
};

export default SettingsInstallments;
