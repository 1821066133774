import React from 'react';
import styles from './styles';

export interface BoxGrayProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactElement;
    customStyles?: string;
}

const BoxGray = (props: BoxGrayProps) => {
    const { children, customStyles, ...boxGrayProps } = props;
    const classes = styles();
    return (
        <div
            {...boxGrayProps}
            className={`${classes.container} ${customStyles || ''}`}
        >
            {children}
        </div>
    );
};

export default BoxGray;
