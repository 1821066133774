import { callPost } from '../graphql';
import { GET_ACCOUNT_FOR_LOGIN } from '../graphql/query';

export const getAccountForLogin = async (token?: string) => {
    const { data: axiosData } = await callPost(
        GET_ACCOUNT_FOR_LOGIN,
        {},
        token,
    );
    const { data } = axiosData;
    return data.bagyPayVindiGetAccount;
};
