import { callPost } from '../graphql';
import { SEARCH_FAQ } from '../graphql/query';

export const searchFAQ = async (textToFilter: string) => {
    const { data: axiosData } = await callPost(SEARCH_FAQ, {
        name: textToFilter,
    });
    const { data } = axiosData;
    return data.searchFAQ;
};
