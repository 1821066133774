import { CREATE_SELLER_SPLIT } from '../graphql/mutations';
import { callPost } from '../graphql';

export const createSellerSplit = async (split: number) => {
    const { data: sellerSplitAxiosData } = await callPost(CREATE_SELLER_SPLIT, {
        split,
    });
    const { data } = sellerSplitAxiosData;

    return data.bagyPayCreateSellerSplits;
};
