/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Menu } from '../Menu';
import styles from './styles';

const MenuSideBarDesktop = () => {
    const classes = styles();

    return (
        <div className={classes.menuContainer}>
            <Menu />
        </div>
    );
};

export default MenuSideBarDesktop;
