import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'menuContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            zIndex: theme.layers.overlay,
            position: 'absolute',
            top: 0,
            height: '100vh',
            width: '100vw',
            opacity: 0,
            backgroundColor: 'rgba(0,0,0, .4)',
            transition: 'opacity .2s ease-in-out',
            '&.-isOpen': {
                opacity: 1,
            },
            [theme.breakpoints.desktop]: {
                display: 'none',
            },
        },
        menuContainer: {
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            position: 'fixed',
            padding: '16px 32px',
            width: 250,
            height: '100%',
            backgroundColor: `${theme.colors.backgroundGray}`,
            transform: 'translateX(-250px)',
            transition: 'transform .2s ease-in-out',
            '&.-isOpen': {
                transform: 'translateX(0)',
            },
        },
    }),
);

export default styles;
