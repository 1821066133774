import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'form'
    | 'inputForm'
    | 'footer'
    | 'forgotPassword'
    | 'login'
    | 'logoContainer'
    | 'buttonForm'
    | 'inputPassword'
    | 'tokenExpiredMessage';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            color: theme.colors.secondaryPure,
            backgroundColor: theme.colors.backgroundGray,
            '& h1': {
                fontSize: 20,
                fontFamily: 'Nunito-black',
                color: '#2F2D2D',
                paddingBottom: 32,
                [theme.breakpoints.tablet]: {
                    fontSize: 24,
                },
            },
        },
        logoContainer: {
            marginTop: 80,
            '& img': {
                width: 155,
            },
            [theme.breakpoints.tablet]: {
                '& img': {
                    width: 215,
                    height: 46,
                },
            },
        },
        form: {
            border: `1px solid ${theme.colors.borderColor}`,
            borderRadius: 16,
            minWidth: 230,
            backgroundColor: theme.colors.white,
            padding: '48px 24px',
            [theme.breakpoints.tablet]: {
                width: 312,
            },
        },
        inputForm: {
            paddingBottom: 24,
        },
        buttonForm: {
            marginTop: 32,
        },
        forgotPassword: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 12,
        },
        login: {
            display: 'flex',
            justifyContent: 'center',
            '& button': {
                maxWidth: 200,
            },
        },
        inputPassword: {
            '& i': {
                color: theme.colors.borderColor,
            },
        },
        footer: {
            marginTop: 20,
            marginBottom: 30,
            fontStyle: 'normal',
            fontSize: 12,
        },
        tokenExpiredMessage: {
            color: theme.colors.primaryPure,
        },
    }),
);

export default styles;
