import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'headerFAQ'
    | 'containerFAQ'
    | 'inputFind'
    | 'contactButtton'
    | 'supportButton'
    | 'emailContainer'
    | 'loading';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            [theme.breakpoints.desktop]: {
                paddingBottom: 32,
            },
        },
        headerFAQ: {
            margin: '24px auto 16px',
            fontSize: 14,
            color: theme.colors.secondaryPure,
            [theme.breakpoints.desktop]: {
                fontSize: 18,
            },
        },
        containerFAQ: {
            margin: '16px auto',
        },
        inputFind: {
            '& i': {
                color: theme.colors.backgroundPlaceholderNeutral,
            },
        },
        contactButtton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 24,
            position: 'fixed',
            bottom: 14,
            right: '50%',
            transform: 'translate(50%)',
            gap: 12,
            width: '100vw',
            [theme.breakpoints.tablet]: {
                transform: 'translate(0)',
                right: 50,
                gap: 12,
                justifyContent: 'flex-end',

            },
        },
        supportButton: {
            textDecoration: ' none',
            borderRadius: 60,
            textAlign: 'center',
            padding: '10px 16px',
            cursor: 'pointer',
            border: '1px solid',
            fontWeight: 900,
            fontSize: 14,
            '&:hover': {
                transition: '0.4s',
                opacity: '0.8',
            },
            backgroundColor: theme.colors.primaryPure,
            color: theme.colors.white,
            borderColor: theme.colors.primaryPure,
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
        emailContainer: {
            '& :before': {
                margin: '0 4px 0 0',
            }
        }
    }),
);

export default styles;
