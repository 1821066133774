import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ContentContainer from '../../components/ContentContainer';
import HeaderMobile from '../../components/HeaderMobile';
import MenuSideBarDesktop from '../../components/MenuSideBarDesktop';
import MenuSideBarMobile from '../../components/MenuSideBarMobile';
import styles from './styles';

export const Dashboard = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const classes = styles();

    return (
        <div className={classes.container}>
            <HeaderMobile onMenuClick={() => setIsMenuOpen(!isMenuOpen)} />
            <MenuSideBarDesktop />
            <MenuSideBarMobile
                isOpen={isMenuOpen}
                onCloseMenu={() => setIsMenuOpen(false)}
            />

            <ContentContainer>
                <Outlet />
            </ContentContainer>
        </div>
    );
};
