import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'contentTables'
    | 'productTable'
    | 'buttonBillet'
    | 'productInformation'
    | 'personalInformation'
    | 'informationBoxes'
    | 'informationTableOne'
    | 'informationTableTwo'
    | 'informationLine'
    | 'informationData'
    | 'informationHeader'
    | 'footerTableDesktop'
    | 'observationProduct'
    | 'totalProduct'
    | 'headerTable'
    | 'inputContainer'
    | 'containerHeaderButton'
    | 'containerFooterButton'
    | 'buttonStyleHeader'
    | 'boxGrayContainer'
    | 'loading'
    | 'deliveryContainer'
    | 'subtitle';
const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            fontSize: 14,
            color: theme.colors.textNeutral,
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
        boxGrayContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 24px',
        },
        contentTables: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.desktop]: {
                gap: 16,
                flexDirection: 'row',
            },
        },
        inputContainer: {
            padding: '8px 6px',

            [theme.breakpoints.desktop]: {
                display: 'none',
            },
        },
        containerHeaderButton: {
            display: 'none',
            [theme.breakpoints.desktop]: {
                display: 'flex',
                maxWidth: '60%',
                gap: 10,
            },
        },
        containerFooterButton: {
            display: 'flex',
            gap: 16,
            minHeight: 35,
            [theme.breakpoints.desktop]: {
                display: 'none',
            },
        },
        buttonBillet: {
            textDecoration: 'none',
            fontSize: 14,
            textAlign: 'center',
            fontWeight: 'bold',
            borderRadius: 8,
            padding: '4px 8px',
            color: theme.colors.textSecondaryNeutral,
            border: `1px solid ${theme.colors.textSecondaryNeutral}`,
            backgroundColor: theme.colors.white,
            '&:focus': {
                outline: 'none',
            },
        },
        buttonStyleHeader: {
            textDecoration: 'none',
            color: theme.colors.textSecondaryNeutral,
            border: `1px solid ${theme.colors.textSecondaryNeutral}`,
            borderRadius: '8px',
            fontSize: 14,
            padding: '4px 8px',
            '&:hover': {
                border: `1px solid ${theme.colors.textSecondaryNeutral}`,
                backgroundColor: theme.colors.borderColor,
            },
        },
        productTable: {
            paddingBottom: 16,
            borderBottom: `1px solid ${theme.colors.borderColor}`,
            '& table': {
                '& tr': {
                    border: 0,
                    padding: 0,
                },
                '& th, td': {
                    textAlign: 'start',
                    margin: 0,
                    fontSize: 14,
                },
                width: '100%',
            },
            [theme.breakpoints.desktop]: {
                display: 'flex',
                '& table': {
                    '& tr': {
                        border: '0 !important',
                    },
                },
            },
        },
        footerTableDesktop: {
            [theme.breakpoints.desktop]: {
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            },
        },

        observationProduct: {
            paddingBottom: 16,
            borderBottom: `1px solid ${theme.colors.borderColor}`,
            [theme.breakpoints.desktop]: {
                flex: 1,
                paddingTop: 16,
                borderBottom: 'none',
                '& div': {
                    flexDirection: 'column',
                    gap: 10,
                },
            },
        },
        totalProduct: {
            display: 'inline',
            justifyContent: 'space-between',
            [theme.breakpoints.desktop]: {
                borderLeft: `1px solid ${theme.colors.borderColor}`,
                padding: '16px 8px 0 16px',
                '&:first-child': {
                    paddingBottom: 16,
                }
            },
        },
        personalInformation: {
            margin: '10px 0',
            [theme.breakpoints.desktop]: {
                flex: 2,
            },
        },
        productInformation: {
            margin: '10px 0',
            [theme.breakpoints.desktop]: {
                flex: 1,
            },
        },
        informationBoxes: {
            display: 'flex',
            alignContent: 'flex-start',
            flexDirection: 'column',
            marginBottom: 10,
            gap: 8,
            [theme.breakpoints.tablet]: {
                flexDirection: 'row',
                marginBottom: 24,
            },
        },
        headerTable: {
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'start',
            [theme.breakpoints.desktop]: {
                margin: '0 0 16px 0',
                textAlign: 'start',
            },
        },
        informationTableOne: {
            fontSize: 14,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.colors.borderColor}`,
            paddingBottom: 16,
            marginBottom: 16,
            alignItems: 'center',
            [theme.breakpoints.desktop]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        deliveryContainer: {
            display: 'block',
            borderTop: `1px solid ${theme.colors.borderColor}`,
            margin: '16px 0 0 0',
            padding: '16px 0 0 0',
        },

        informationTableTwo: {
            fontSize: 14,
            [theme.breakpoints.desktop]: {
                justifyContent: 'space-between',
            },
        },
        informationLine: {
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'start',
            margin: '8px 0',
            [theme.breakpoints.desktop]: {
                marginBottom: 0,
            },
        },
        informationHeader: {
            fontWeight: 'bold',
            marginRight: 8,
            whiteSpace: 'nowrap',
        },
        informationData: {
            whiteSpace: 'break-spaces',
            wordBreak: 'break-word',
            maxWidth: 200,
            '& a': {
                color: theme.colors.textNeutralPlaceholder,
            },
        },
        subtitle: {
            fontWeight: '500',
            marginBottom: 24,
        },
    }),
);

export default styles;
