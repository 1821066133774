import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux';
import { GlobalModalType, hideModal } from '../../../redux/slices';
import Button from '../../Button';
import styles from './styles';

export const ConfirmModal = () => {
    const classes = styles();
    const dispatch = useDispatch();
    const { data } = useSelector<RootStateType, GlobalModalType>(
        (state) => state.globalModal,
    );

    const { message, title, onNoClick, onYesClick } = data;
    return (
        <div className={classes.modalContent}>
            <h1 className={classes.modalTitle}>{title}</h1>
            <p className={classes.modalTextContent}>{message}</p>
            <div className={classes.buttonContainer}>
                <Button
                    title="Não"
                    onClick={() => {
                        if (onNoClick) {
                            onNoClick();
                        }
                        dispatch(hideModal());
                    }}
                />
                <Button
                    title="Sim"
                    typeButton="outline"
                    onClick={() => {
                        if (onYesClick) {
                            onYesClick();
                        }
                        dispatch(hideModal());
                    }}
                />
            </div>
        </div>
    );
};
