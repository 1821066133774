/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from 'react';
import styles from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title?: string;
    typeButton?: 'outline' | 'link' | 'default';
    styleWrapper?: string;
}

const Button = (props: React.PropsWithChildren<ButtonProps>) => {
    const {
        title,
        typeButton = 'default',
        type = 'button',
        styleWrapper,
        children,
        ...buttonProps
    } = props;

    const classes = styles();

    return (
        <button
            type={type}
            {...buttonProps}
            className={`${classes.buttonContainer} ${classes[typeButton]} ${
                styleWrapper || ''
            }`}
        >
            {children || title}
        </button>
    );
};

export default Button;
