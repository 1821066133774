import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'logoContainer' | 'iconContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            backgroundColor: `${theme.colors.white}`,
            display: 'flex',
            width: '100%',
            boxSizing: 'border-box',
            borderBottom: '1px solid #ddd',
            position: 'fixed',
            alignItems: 'center',
            padding: 16,
            zIndex: theme.layers.headerMobile,
            '& > :first-child': {
                paddingLeft: 0,
            },
            [theme.breakpoints.desktop]: {
                display: 'none',
            },
            '& button': {
                zIndex: Number.MAX_SAFE_INTEGER,
            }
        },
        logoContainer: {
            flex: 1,
            display: 'flex',
            position: 'fixed',
            width: '100vw',
            justifyContent: 'center',
            '& img': {
                width: 125,
                paddingRight: 32,
            },
        },
        iconContainer: {
            color: theme.colors.primaryPure,
            fontSize: 20,
        },
    }),
);

export default styles;
