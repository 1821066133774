import React from 'react';
import styles from './styles';

const ContentContainer = ({ children }: React.PropsWithChildren) => {
    const classes = styles();

    return <main className={classes.container}>{children}</main>;
};

export default ContentContainer;
