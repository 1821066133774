import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'input'
    | 'label'
    | 'error'
    | 'rightIconContainer'
    | 'inputContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
        },
        label: {
            marginBottom: 8,
            fontWeight: 500,
            fontSize: 14,
            color: theme.colors.secondaryPure,
        },
        input: {
            border: 'none',
            flex: 1,
            fontSize: '12px !important',
            fontFamily: 'Nunito-medium',
            '&:focus': {
                outline: 'none !important',
            },
            [theme.breakpoints.desktop]: {
                fontSize: '14px !important',
            },
        },
        error: {
            color: theme.colors.primaryPure,
            fontSize: 14,
            marginTop: 10,
        },
        rightIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            '& button': {
                backgroundColor: 'transparent',
                padding: 0,
            },
        },
        inputContainer: {
            display: 'flex',
            padding: '8px 12px',
            border: `1px solid ${theme.colors.borderColor}`,
            borderRadius: 8,
            '&.-focus-style': {
                borderColor: theme.colors.primaryPure,
            },
        },
    }),
);

export default styles;
