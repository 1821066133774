import { loader } from 'graphql.macro';

const LOGIN = loader('./login.gql');
const CREATE_SELLER_SPLIT = loader('./createSellerSplits.gql');
const CANCEL_TRANSACTION = loader('./bagyPayVindiCancelTransaction.gql');
const CREATE_BANK_ACCOUNT = loader('./bagyPayCreateBankAccount.gql');
const DELETE_BANK_ACCOUNT = loader('./bagyPayDeleteBankAccount.gql');
const CREATE_WITHDRAWAL = loader('./createWithdrawal.gql');
const REFRESH_TOKEN = loader('./bagyPayVindiRefreshToken.gql');

export {
    LOGIN,
    CREATE_SELLER_SPLIT,
    CREATE_WITHDRAWAL,
    CANCEL_TRANSACTION,
    CREATE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    REFRESH_TOKEN,
};
