import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BoxGray from '../../components/BoxGray';
import BoxInformation from '../../components/BoxInformation';
import Button from '../../components/Button';
import CircleLoading from '../../components/CircleLoading';
import ContentHeader from '../../components/ContentHeader';
import { showToastError, showToastSuccess } from '../../components/Toast';
import {
    METHOD_PAYMENT_BAGY_PAY,
    TRANSACTION_STATUS,
    TRANSACTION_STATUS_LABEL,
} from '../../constants';
import { hideModal, showModal } from '../../redux/slices';
import { getSales } from '../../services/getSales/getSales';
import {
    SaleOptionsType,
    SaleType,
} from '../../services/getSales/getSales.type';
import { cancelTransaction } from '../../services/transaction/cancelTransaction';
import { getTransaction } from '../../services/transaction/getTransaction';
import { TransactionType } from '../../services/types/transaction.type';
import { formatCNPJ, formatCPF, formatCurrency } from '../../utils/formatter';
import styles from './styles';
import Table from '../../components/Table';
import { TableHeader, TableProps } from '../../components/Table/types';
import { showErrorAsToast } from '../../utils/errors/showErrorAsToast';

const DetailsTransaction = () => {
    const classes = styles();
    const { id: transactionId } = useParams();
    const [transaction, setTransaction] = useState<SaleType>();
    const [tableDetailsTransaction, setTableDetailsTransaction] = useState<
        DetailsTransactionType[]
    >([]);
    const [summaryOrder, setSummaryOrder] = useState<SaleOptionsType>();
    const [urlPayment, setUrlPayment] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const loadUrlPayment = async (transactionToken: string) => {
        const paymentInformationsData: TransactionType = await getTransaction(
            transactionToken,
        );
        const { payment } = paymentInformationsData || {};
        setUrlPayment(payment?.urlPayment);
    };

    type DetailsTransactionType = {
        code: number;
        description: string;
        quantity: number;
        unitPrice: number;
    };

    const idxColsHideOnMobile: number[] = [1];
    const headers: TableHeader = {
        code: { title: 'Código', order: 0 },
        description: { title: 'Descrição', order: 1 },
        quantity: { title: 'Quantidade', order: 2 },
        unitPrice: { title: 'Valor', order: 3 },
    };

    const loadDetailsTransactions = async () => {
        setIsLoading(true);
        const salesData = await getSales({ id: Number(transactionId) });
        const { options, sales } = salesData;
        if (sales.length === 0) {
            setIsLoading(false);
            return;
        }
        setSummaryOrder(options);
        const transacationData = sales[0];
        const { transactionToken } = transacationData;
        loadUrlPayment(transactionToken);
        setTransaction(transacationData);

        const transanstionsTable: DetailsTransactionType[] =
            transacationData.transactionProducts &&
            transacationData.transactionProducts.map<DetailsTransactionType>(
                ({ code, description, quantity, unitPrice }) => ({
                    code: +code,
                    description,
                    quantity: +quantity,
                    unitPrice,
                }),
            );

        setTableDetailsTransaction(transanstionsTable);
        setIsLoading(false);
    };

    const cancelSale = async (id: number) => {
        try {
            setIsLoading(true);
            await cancelTransaction(id);
            setTransaction((trans) => {
                if (!trans) return trans;
                return {
                    ...trans,
                    status: {
                        name: TRANSACTION_STATUS.CANCELED,
                        id: 1,
                        resource: '',
                    },
                };
            });
            showToastSuccess({
                title: 'Tudo certo!',
                message: ' Sua transação foi cancelada.',
            });
        } catch (error: any) {
            showErrorAsToast({
                error,
                message: 'Ocorreu um problema ao cancelar sua transação.',
                showToast: showToastError,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const confirmCancelTransaction = async (id: number) => {
        dispatch(
            showModal({
                data: {
                    title: 'Confirmar ação de cancelamento',
                    message: 'Você realmente deseja cancelar essa transação?',
                    onYesClick: () => cancelSale(id),
                    onNoClick: () => hideModal(),
                    buttonText: '',
                },
                modalType: 'CONFIRM_MODAL',
            }),
        );
    };

    const getTableProps = useCallback(
        (): TableProps<DetailsTransactionType> => ({
            headers,
            idxColsHideOnMobile,
            listData: tableDetailsTransaction,
            dataConfig: {
                quantity: {
                    renderComponent: ({ value }) => (
                        <tr>
                            {`${value} unidade`}
                            {value && +value > 1 && 's'}
                        </tr>
                    ),
                },
            },
        }),
        [tableDetailsTransaction],
    );

    useEffect(() => {
        loadDetailsTransactions();
    }, []);

    const showBagyOrderId =
        transaction && +transaction.orderNumber !== transaction.id;

    const showCancelBtn =
        transaction &&
        [
            TRANSACTION_STATUS.APPROVED,
            TRANSACTION_STATUS.WAITING_PAYMENT,
        ].includes(transaction.status.name);

    return (
        <div className={classes.container}>
            <ContentHeader
                iconName="icon-chat-money"
                title="Transações"
                subtitle={
                    showBagyOrderId
                        ? `Cód. do pedido Bagy ${
                              transaction ? transaction.orderNumber : ''
                          }`
                        : ''
                }
                showBackButton
            />
            {isLoading && (
                <div className={classes.loading}>
                    <CircleLoading />
                </div>
            )}
            {!isLoading && transaction && (
                <>
                    <div className={classes.informationBoxes}>
                        <BoxInformation
                            spanTextOne="transação"
                            textInformation={`${transaction?.id}`}
                            iconName="icon-chat-money"
                            spanTextTwo={moment(transaction?.created).format(
                                'DD/MM/YYYY - kk:mm',
                            )}
                        />
                        <BoxInformation
                            spanTextOne="status"
                            textInformation={
                                TRANSACTION_STATUS_LABEL[
                                    transaction.status?.name ||
                                        TRANSACTION_STATUS.WAITING_PAYMENT
                                ]
                            }
                            spanTextTwo=" "
                            iconName="icon-bars"
                        />
                        <BoxInformation
                            spanTextOne="método de pagamento"
                            textInformation={`${
                                transaction.payment.installments
                            }x de ${formatCurrency(
                                Number(summaryOrder?.sumPricePayment) /
                                    transaction.payment.installments,
                            )}`}
                            iconName="icon-credit-card-fulled"
                            spanTextTwo={`${transaction.payment.paymentMethodName}`}
                        />
                    </div>
                    <div className={classes.contentTables}>
                        <div className={classes.personalInformation}>
                            <BoxGray customStyles={classes.boxGrayContainer}>
                                <>
                                    <div
                                        className={`${classes.informationTableOne}`}
                                    >
                                        <h1 className={classes.headerTable}>
                                            Produtos
                                        </h1>
                                        {!!urlPayment && (
                                            <div
                                                className={
                                                    classes.inputContainer
                                                }
                                            >
                                                <a
                                                    href={urlPayment}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={
                                                        classes.buttonStyleHeader
                                                    }
                                                >
                                                    Vizualizar boleto
                                                </a>
                                            </div>
                                        )}

                                        <div
                                            className={
                                                classes.containerHeaderButton
                                            }
                                        >
                                            {transaction.payment
                                                .paymentMethodId ===
                                                METHOD_PAYMENT_BAGY_PAY.billet && (
                                                <a
                                                    href={urlPayment}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={
                                                        classes.buttonBillet
                                                    }
                                                >
                                                    Vizualizar boleto
                                                </a>
                                            )}
                                            {showCancelBtn && (
                                                <Button
                                                    styleWrapper={
                                                        classes.buttonStyleHeader
                                                    }
                                                    onClick={() => {
                                                        confirmCancelTransaction(
                                                            Number(
                                                                transactionId,
                                                            ),
                                                        );
                                                    }}
                                                    title="Cancelar transação"
                                                    typeButton="outline"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.productTable}>
                                        <Table
                                            {...getTableProps()}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <div className={classes.footerTableDesktop}>
                                        <div
                                            className={
                                                classes.observationProduct
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    Observações:
                                                </span>
                                                <span
                                                    className={
                                                        classes.informationData
                                                    }
                                                >
                                                    {transaction.transactionProducts.map(
                                                        (product) =>
                                                            product.extra,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={classes.totalProduct}>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    A Receber:
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    TOTAL:
                                                </span>
                                                <span
                                                    className={
                                                        classes.informationData
                                                    }
                                                >
                                                    {formatCurrency(
                                                        Number(
                                                            summaryOrder?.sumPriceSeller,
                                                        ),
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={classes.totalProduct}>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    A pagar:
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    Frete:
                                                </span>
                                                <span
                                                    className={
                                                        classes.informationData
                                                    }
                                                >
                                                    {formatCurrency(
                                                        transaction.shipping
                                                            ?.shippingPrice,
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    Taxa:
                                                </span>
                                                <span
                                                    className={
                                                        classes.informationData
                                                    }
                                                >
                                                    {formatCurrency(
                                                        Number(
                                                            summaryOrder?.sumTax,
                                                        ),
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    classes.informationLine
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.informationHeader
                                                    }
                                                >
                                                    TOTAL:
                                                </span>
                                                <span
                                                    className={
                                                        classes.informationData
                                                    }
                                                >
                                                    {formatCurrency(
                                                        Number(
                                                            summaryOrder?.sumPricePayment,
                                                        ),
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </BoxGray>
                        </div>
                        {showCancelBtn && (
                            <div className={classes.containerFooterButton}>
                                <Button
                                    typeButton="outline"
                                    title="Cancelar transação"
                                    styleWrapper={classes.buttonStyleHeader}
                                    onClick={() => {
                                        cancelSale(Number(transactionId));
                                    }}
                                />
                            </div>
                        )}
                        <div className={classes.productInformation}>
                            <BoxGray customStyles={classes.boxGrayContainer}>
                                <div className={classes.informationTableTwo}>
                                    <h1 className={classes.headerTable}>
                                        Dados do comprador
                                    </h1>
                                    <div className={classes.informationLine}>
                                        <span
                                            className={
                                                classes.informationHeader
                                            }
                                        >
                                            Nome:
                                        </span>
                                        <span
                                            className={classes.informationData}
                                        >
                                            {transaction.customer.name}
                                        </span>
                                    </div>
                                    <div className={classes.informationLine}>
                                        <span
                                            className={
                                                classes.informationHeader
                                            }
                                        >
                                            E-mail:
                                        </span>
                                        <span
                                            className={classes.informationData}
                                        >
                                            {transaction.customer.email}
                                        </span>
                                    </div>
                                    <div className={classes.informationLine}>
                                        <span
                                            className={
                                                classes.informationHeader
                                            }
                                        >
                                            {transaction.customer.cpf
                                                ? 'CPF:'
                                                : 'CNPJ:'}
                                        </span>
                                        <span
                                            className={classes.informationData}
                                        >
                                            {transaction.customer.cpf
                                                ? formatCPF(
                                                      transaction.customer.cpf,
                                                  )
                                                : formatCNPJ(
                                                      transaction.customer.cnpj,
                                                  )}
                                        </span>
                                    </div>
                                    <div className={classes.deliveryContainer}>
                                        <h1 className={classes.headerTable}>
                                            Entrega:
                                        </h1>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                Envio:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping
                                                        .shippingType
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                CEP:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping.address
                                                        ?.postalCode
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                Endereço:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping.address
                                                        ?.street
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                Complemento:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping.address
                                                        ?.complement
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                Bairro:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping.address
                                                        ?.neighborhood
                                                }
                                            </span>
                                        </div>
                                        <div
                                            className={classes.informationLine}
                                        >
                                            <span
                                                className={
                                                    classes.informationHeader
                                                }
                                            >
                                                Cidade:
                                            </span>
                                            <span
                                                className={
                                                    classes.informationData
                                                }
                                            >
                                                {
                                                    transaction.shipping.address
                                                        ?.city
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BoxGray>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DetailsTransaction;
