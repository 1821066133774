import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'information'
    | 'headerInformationContainer'
    | 'headerInformation'
    | 'textInformation'
    | 'informationBoxes'
    | 'informationBox'
    | 'tableContainer'
    | 'tableHeader'
    | 'statusTable'
    | 'textInformationButton'
    | 'loading';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            maxWidth: 450,
            margin: '0 auto',
            [theme.breakpoints.tablet]: {
                maxWidth: '100%',
            },
        },
        textInformationButton: {
            fontSize: '8px !important',
            [theme.breakpoints.tablet]: {
                display: 'none',
            },
        },
        information: {
            marginBottom: 16,
            color: theme.colors.textNeutralPlaceholder,
            fontSize: 14,
            lineHeight: '1.5',
            [theme.breakpoints.desktop]: {
                fontSize: 16,
                lineHeight: '1.8',
                marginBottom: 32,
            },
        },
        headerInformationContainer: {
            display: 'flex',
            gap: 8,
        },
        headerInformation: {
            fontWeight: 'bold',
        },
        textInformation: {
            marginTop: 20,

        },
        informationBoxes: {
            gap: 16,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.tablet]: {
                display: 'flex',
                flexDirection: 'row',
            },
            '& > div:hover ': {
                backgroundColor: 'rgba(252, 136, 185, .1)',
            },
        },
        informationBox: {
            flex: 1,
        },
        tableContainer: {
            '& table thead tr': {
                borderRadius: '8px !important',
            }
        },
        tableHeader: {
            color: theme.colors.textNeutralPlaceholder,
            marginTop: 32,
            fontWeight: 500,
            fontSize: 16,
            [theme.breakpoints.tablet]: {
                fontSize: 18,
            },
        },
        statusTable: {
            padding: 5,
            borderRadius: 6,
            color: theme.colors.backgroundOrange,
            border: `1px solid ${theme.colors.backgroundOrange}`,
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
    }),
);

export default styles;
