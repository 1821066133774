/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Icon } from '../Icon';
import styles from './styles';

interface EmailContactButtonProps {
    mailto: string;
}

const EmailContactButton = (props: EmailContactButtonProps) => {
    const { mailto } = props;
    const classes = styles();
    return (
        <a className={classes.container} href={`mailto:${mailto}`}>
            <Icon iconName="icon-mail" />
        </a>
    );
};

export default EmailContactButton;
