import React from 'react';
import styles from './styles';

const Extract = () => {
    const classes = styles();

    return <div className={classes.container}>Extract</div>;
};

export default Extract;
