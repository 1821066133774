import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'header'
    | 'headerInformation'
    | 'textInformation'
    | 'cardContainer'
    | 'setInstallments'
    | 'button'
    | 'downButton'
    | 'upButton'
    | 'footer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        header: {
            color: theme.colors.textNeutral,
            fontSize: 16,
            paddingBottom: 16,
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.colors.borderColor}`,
            [theme.breakpoints.desktop]: {
                fontSize: 18,
            },
        },
        headerInformation: {
            color: theme.colors.textNeutral,
            fontSize: 14,
            fontWeight: 'bold',
            margin: '16px 0 8px 0',
        },
        textInformation: {
            fontSize: 14,
            color: theme.colors.textNeutral,
            lineHeight: 1.2,
            paddingBottom: 8,
        },
        cardContainer: {
            marginTop: 16,
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'row wrap',
            '& div': {
                width: '25%',
            },
            gap: 4,
        },
        setInstallments: {
            margin: '24px 0',
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            '& input': {
                fontSize: 12,
                height: 35,
                width: 50,
                color: theme.colors.textNeutral,
                textAlign: 'center',
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.borderColor}`,
                borderRadius: 8,
                [theme.breakpoints.desktop]: {
                    fontSize: 16,
                },
            },
        },
        button: {
            textAlign: 'center',
            width: 30,
            fontFamily: 'Nunito-black',
            fontSize: 24,
            borderRadius: 50,
            border: 'none',
            cursor: 'pointer',
            opacity: .5,
            fontWeight: 'bolder',
            padding: 0,
        },
        upButton: {
            backgroundColor: theme.colors.backgroundSecondaryColor,
            color: theme.colors.primaryPure,
        },
        downButton: {
            backgroundColor: theme.colors.blueWaiting,
            color: theme.colors.blueProcess,
        },
        footer: {
            fontFamily: 'Nunito-black',
            maxWidth: 225,
            margin: '24px auto',
            [theme.breakpoints.desktop]: {
                margin: '24px 0',
                display: 'flex',
                alignContent: 'start',
            },
        },
    }),
);

export default styles;
