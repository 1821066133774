import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoPng from '../../assets/images/logo.svg';
import { RootStateType } from '../../redux';
import useAuthHook from '../../utils/hooks/auth.hooks';
import AccountMenuItem from '../AccountMenuItem';
import Button from '../Button';
import { Icon } from '../Icon';
import styles from './style';

export const Menu = () => {
    const classes = styles();
    const { signOut } = useAuthHook();
    const { email, name } = useSelector((state: RootStateType) => state.auth);

    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <img src={LogoPng} alt="Logomarca do Bagy Pay" />
            </div>
            <nav className={classes.navContainer}>
                <Link className={classes.navItem} to="/home">
                    <Icon iconName="icon-home" />
                    Início
                </Link>
                <Link className={classes.navItem} to="/transacoes">
                    <Icon iconName="icon-chat-money" />
                    Transações
                </Link>
                <Link className={classes.navItem} to="/saques">
                    <Icon iconName="icon-hand-money" />
                    Saques
                </Link>
                <Link className={classes.navItem} to="/parcelas">
                    <Icon iconName="icon-gear" />
                    Parcelas
                </Link>
                <Link className={classes.navItem} to="/minha-conta">
                    <Icon iconName="icon-people" />
                    Minha Conta
                </Link>
                <Link className={classes.navItem} to="/ajuda">
                    <Icon iconName="icon-people-support" />
                    Ajuda
                </Link>
                <Button
                    typeButton="link"
                    styleWrapper={classes.logoutButton}
                    onClick={signOut}
                >
                    <Icon iconName="icon-exit" />
                    Sair
                </Button>
            </nav>
            <div className={classes.accountContainer}>
                <AccountMenuItem email={email} name={name} />
            </div>
        </div>
    );
};
