/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { showToastWarning } from '../../../components/Toast';
import { createBankAccount } from '../../../services/bankAccount/createBankAccount';
import { bankAccountValidationSchema } from './bankAccountValidationSchema';
import stylesInherited from '../styles';
import styles from './styles';
import { Input } from '../../../components/Input';
import Button from '../../../components/Button';
import { deleteBankAccount } from '../../../services/bankAccount/deleteBankAccount';

type BankAccountFormProps = {
    isUpdate: boolean;
    bankAccountId: number;
    onBankAccountChange?: () => void;
};
export const BankAccountForm = (props: BankAccountFormProps) => {
    const { isUpdate, bankAccountId, onBankAccountChange } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const classes = { ...styles(), ...stylesInherited() };

    const removeOldAccountBank = async (bankId: number) => {
        await deleteBankAccount(bankId);
    };
    const submitBankAccount = async ({
        agencyBank,
        bankCode,
        numberAccount,
        typeAccount,
    }: {
        agencyBank: string;
        bankCode: string;
        numberAccount: string;
        typeAccount: string;
    }) => {
        try {
            setIsSubmitting(true);
            if (isUpdate) {
                await removeOldAccountBank(bankAccountId);
            }
            await createBankAccount(
                bankCode,
                agencyBank,
                numberAccount,
                typeAccount,
            );
            if (onBankAccountChange) {
                onBankAccountChange();
            }
        } catch {
            showToastWarning({
                title: 'Desculpe',
                message:
                    'Não foi possivel concluir o cadastro. Tente novamente.',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const { values, errors, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: {
                agencyBank: '',
                bankCode: '',
                numberAccount: '',
                typeAccount: 'C',
            },
            onSubmit: submitBankAccount,

            validationSchema: bankAccountValidationSchema,
        });

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.selectForm}>
                <label>Selecione o tipo da conta:</label>
                <select
                    name="typeAccount"
                    value={values.typeAccount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value="C" selected>
                        Conta corrente
                    </option>
                    <option value="S">Conta poupança</option>
                </select>
            </div>
            <div className={classes.inputForm}>
                <Input
                    label="Digite o código do seu banco:"
                    name="bankCode"
                    value={values.bankCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.bankCode}
                />
            </div>
            <div className={classes.inputForm}>
                <Input
                    label="Digite o número da sua agência:"
                    name="agencyBank"
                    value={values.agencyBank}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.agencyBank}
                />
            </div>
            <div className={classes.inputForm}>
                <Input
                    label="Digite o número da sua conta com dígito verificador:"
                    name="numberAccount"
                    value={values.numberAccount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.numberAccount}
                />
            </div>
            <div className={classes.buttonForm}>
                <Button
                    type="submit"
                    title={isSubmitting ? 'Aguarde ...' : 'Salvar'}
                    disabled={isSubmitting}
                />
            </div>
        </form>
    );
};
