import React, { HTMLAttributes } from 'react';
import { IconNames } from './icon-names.type';

export interface IconProps extends HTMLAttributes<HTMLLIElement> {
    iconName: IconNames;
    customStyles?: string;
}

export const Icon = ({ iconName, customStyles, ...iProps }: IconProps) => (
    <i {...iProps} className={`${iconName} ${customStyles || ''}`} />
);
