import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            padding: 4,
            backgroundColor: theme.colors.white,
            border: `1px solid ${theme.colors.borderColor}`,
            borderRadius: 4,
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: 55,
        },
    }),
);

export default styles;
