import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            borderRadius: 60,
            width: '100%',
            textAlign: 'center',
            padding: 10,
            cursor: 'pointer',
            lineHeight: '16px',
            fontWeight: 900,
            fontSize: 14,
            backgroundColor: theme.colors.backgroundSecondaryColor,
            color: theme.colors.white,
            borderColor: theme.colors.primaryPure,
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
            '&:hover': {
                color: theme.colors.primaryPure,
                backgroundColor: theme.colors.white,
                transition: '0.4s',
                opacity: '0.8',
            },
        },
    }),
);
export default styles;
