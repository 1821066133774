import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'modalContainer'
    | 'modalTitle'
    | 'modalTextContent';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,.4)',
            top: '0',
            right: '0',
            width: '100%',
            height: '100%',
            zIndex: theme.layers.modal,
            opacity: 0,
            transition: 'opacity .2s ease-in-out',
            '&.-isOpen': {
                opacity: 1,
                transition: 'opacity .2s ease-in-out',
            },
        },
        modalContainer: {
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 20,
            backgroundColor: '#fff',
            maxWidth: 476,
            minWidth: 300,
            borderRadius: 16,
            padding: '30px 45px',
        },
        modalTitle: {
            fontWeight: 'bold',
            fontSize: 20,
            textAlign: 'center',
        },
        modalTextContent: {
            margin: '32px 0',
            textAlign: 'center',
            fontSize: 16,
            lineHeight: 1.22,
        },
    }),
);

export default styles;
