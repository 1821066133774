import React from 'react';
import styles from './styles';

export interface CardBoxType extends React.HTMLAttributes<HTMLDivElement> {
    imgUrl: string;
    customStyles?: string;
}

const CardBox = (props: CardBoxType) => {
    const { imgUrl, customStyles, ...boxGrayProps } = props;
    const classes = styles();
    return (
        <div
            {...boxGrayProps}
            className={`${classes.container} ${customStyles || ''}`}
        >
            <img src={imgUrl} alt="Bandeira do cartão" />
        </div>
    );
};

export default CardBox;
