import React from 'react';
import BoxGray from '../BoxGray';
import styles from './styles';

interface BalanceWithdrawalBoxProps {
    title: string;
    isRequestWithdrawal?: boolean;
    inputWithdrawal?: React.ReactElement;
    valueWithdrawal?: string;
    taxOfWithdrawal?: string;
    balanceValue?: number;
    statusOfWithdrawal?: string;
    dateOfWithdrawal?: string;
    errors?: string;
}
const BalanceWithdrawalBox = (props: BalanceWithdrawalBoxProps) => {
    const {
        title,
        valueWithdrawal,
        taxOfWithdrawal,
        statusOfWithdrawal,
        dateOfWithdrawal,
        isRequestWithdrawal = false,
        inputWithdrawal,
        balanceValue,
        errors,
    } = props;
    const classes = styles();
    return (
        <BoxGray>
            <div className={classes.container}>
                <span>{title}</span>
                <div className={classes.header}>
                    {isRequestWithdrawal && (
                        <div className={classes.inputWithdrawal}>
                            <div>
                                <span>R$</span>
                                {inputWithdrawal}
                            </div>
                            {errors && (
                                <span className={classes.errors}>{errors}</span>
                            )}
                        </div>
                    )}
                    {!isRequestWithdrawal && <h1>R$ {valueWithdrawal}</h1>}

                    <p>{statusOfWithdrawal}</p>
                </div>

                <div className={classes.footerDetails}>
                    {isRequestWithdrawal && (
                        <p>Saldo total: R${balanceValue}</p>
                    )}

                    {!isRequestWithdrawal && (
                        <>
                            <p>Taxa de saque: R$ {taxOfWithdrawal}</p>
                            <p>{dateOfWithdrawal}</p>
                        </>
                    )}
                </div>
            </div>
        </BoxGray>
    );
};
export default BalanceWithdrawalBox;
