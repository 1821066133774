import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'informationBoxes'
    | 'personalInformation'
    | 'informationTable'
    | 'headerTable'
    | 'loading'
    | 'selectForm'
    | 'inputForm'
    | 'buttonForm'
    | 'headerBankAccount';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {},
        informationBoxes: {
            gap: 16,
            marginBottom: 8,

            [theme.breakpoints.tablet]: {
                display: 'flex',
                flexDirection: 'row',
            },
        },
        personalInformation: {
            margin: '16px auto',
        },
        informationTable: {
            fontSize: 14,
        },
        headerTable: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 16,
            paddingBottom: 16,
            width: '100%',
            fontWeight: 'bold',
            borderBottom: `1px solid ${theme.colors.borderColor}`,
            [theme.breakpoints.desktop]: {
                fontSize: 18,
            },
        },
        selectForm: {
            display: 'flex',
            gap: 8,
            margin: '16px 0',
            '& label': { color: theme.colors.textNeutral },
        },
        inputForm: {
            '& label': theme.colors.backgroundGray,
            marginBottom: 14,
            '@media (min-width: 1024px)': {
                marginBottom: 16,
            },
        },
        buttonForm: {
            display: 'flex',
            marginTop: 14,
            justifyContent: 'center',
            '& button': {
                maxWidth: 200,
            },
        },
        // informationData: {
        //     color: theme.colors.textNeutralPlaceholder,
        //     wordBreak: 'break-word',
        //     '& a': {
        //         color: theme.colors.textNeutralPlaceholder,
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
        headerBankAccount: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }),
);

export default styles;
