/* eslint-disable @typescript-eslint/no-unused-expressions */
export const formatCurrency = (amount: number) =>
    `R$ ${amount.toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}`;

export const formatTaxesType = (type: string, value: number) => {
    if (type === 'currency') {
        return formatCurrency(value);
    }
    return `${value}%`;
};

export const formatCPF = (cpf: string) =>
    `${cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}`;

export const formatCNPJ = (cnpj: string) =>
    `${cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}`;
