import React from 'react';

import styles from './styles';

const CircleLoading = () => {
    const classes = styles();
    return (
        <div className={classes.container}>
            <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default CircleLoading;
