import { REFRESH_TOKEN } from '../graphql/mutations';
import { callPost } from '../graphql';

export const refreshToken = async (token?: string) => {
    const { data: bagyPayVindiRefreshTokenAxiosData } = await callPost(
        REFRESH_TOKEN,
        {},
        token,
    );
    const { data } = bagyPayVindiRefreshTokenAxiosData;

    return data.bagyPayVindiRefreshToken;
};
