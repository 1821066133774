import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'header'
    | 'inputWithdrawal'
    | 'errors'
    | 'footerDetails';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            color: theme.colors.textNeutral,
            fontSize: 18,
        },

        header: {
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'end',
            '& h1': {
                marginTop: 16,
                fontSize: 24,
                fontWeight: 'bold',
            },
            '& p': {
                fontSize: 12,
                alignSelf: 'flex-end',
            },
        },
        inputWithdrawal: {
            textAlign: 'start',
            padding: 8,
            backgroundColor: theme.colors.white,
            margin: '16px 0',
            borderRadius: 8,
            border: `1px solid ${theme.colors.borderColor}`,
            fontSize: 16,
            '& input': {
                fontSize: 16,
                verticalAlign: 'center',
                marginLeft: 4,
                border: 'none',
                maxWidth: 80,
            },
        },
        errors: {
            textAlign: 'start',
            color: theme.colors.primaryPure,
            fontSize: 14,
            marginTop: 10,
        },
        footerDetails: {
            display: 'flex',
            margin: '4px 0',
            fontSize: 12,
            justifyContent: 'space-between',
            alignContent: 'center',
            textAlign: 'centers',

            [theme.breakpoints.desktop]: {
                margin: '8px 0',
            },
        },
    }),
);

export default styles;
