import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Extract from '../pages/Extract';
import Home from '../pages/Home';
import Transactions from '../pages/Transactions';
import Withdrawals from '../pages/Withdrawals';
import MyAccount from '../pages/MyAccount';
import Settings from '../pages/Settings';
import Help from '../pages/Help';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import { RootStateType } from '../redux';
import { useGlobalStyles } from '../styles';
import TableExampleTwo from '../pages/TableExampleTwo';
import DetailsWithdrawals from '../pages/DetailsWithdrawals';
import DetailsTransaction from '../pages/DetailsTransaction';
import SettingsInstallments from '../pages/SettingsInstallments';
import RequestWithdrawal from '../pages/RequestWithdrawal';

export const Router = () => {
    useGlobalStyles();
    const { token } = useSelector((state: RootStateType) => state.auth);
    return (
        <BrowserRouter>
            <Routes>
                {!token ? (
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/*"
                            element={<Navigate to="/login" replace />}
                        />
                    </>
                ) : (
                    <Route path="/" element={<Dashboard />}>
                        <Route path="home" element={<Home />} />
                        <Route path="transacoes" element={<Transactions />} />
                        <Route
                            path="transacoes/:id"
                            element={<DetailsTransaction />}
                        />
                        <Route path="extratos" element={<Extract />} />
                        <Route path="saques" element={<Withdrawals />} />
                        <Route
                            path="solicitar-saque"
                            element={<RequestWithdrawal />}
                        />
                        <Route
                            path="saques/:id"
                            element={<DetailsWithdrawals />}
                        />
                        <Route path="configuracoes" element={<Settings />} />
                        <Route path="minha-conta" element={<MyAccount />} />
                        <Route
                            path="parcelas"
                            element={<SettingsInstallments />}
                        />
                        <Route path="ajuda" element={<Help />} />
                        <Route
                            path="table-example-2"
                            element={<TableExampleTwo />}
                        />
                        <Route
                            path="/"
                            element={<Navigate to="/home" replace />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/home" replace />}
                        />
                    </Route>
                )}
            </Routes>
        </BrowserRouter>
    );
};
