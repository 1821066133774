import { callPost } from '../graphql';
import { CREATE_BANK_ACCOUNT } from '../graphql/mutations';

export const createBankAccount = async (
    bankCode: string,
    agency: string,
    account: string,
    typeAccount: string,
) => {
    const { data: axiosData } = await callPost(CREATE_BANK_ACCOUNT, {
        bankCode,
        agency,
        account,
        typeAccount,
    });
    const { data } = axiosData;
    return data.bagyPayCreateBankAccount;
};
