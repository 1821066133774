import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames =
    | 'container'
    | 'informationBoxes'
    | 'statusTableWaiting'
    | 'buttonTable'
    | 'tableContainer'
    | 'loading';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            font: 20,
        },
        informationBoxes: {
            gap: 16,
            marginBottom: 8,
            [theme.breakpoints.tablet]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                '& > div:hover ': {
                    backgroundColor: 'rgba(252, 136, 185, .1)',
                },
            },
        },
        statusTableWaiting: {
            display: 'inline-block',
            fontSize: 12,
            padding: 8,
            color: theme.colors.blueWaiting,
            border: `1px solid ${theme.colors.blueWaiting}`,
            borderRadius: 8,

            [theme.breakpoints.desktop]: {
                fontSize: 14,
            },
        },
        buttonTable: {
            fontSize: 12,
            color: theme.colors.textNeutralPlaceholder,
            border: `1px soid ${theme.colors.textNeutralPlaceholder}`,
            [theme.breakpoints.desktop]: {
                fontSize: 14,
            },
        },

        tableContainer: {
            marginTop: 24,
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
    }),
);

export default styles;
