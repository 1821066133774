import { AxiosError } from 'axios';
import { store } from '../../redux';
import { logout } from '../../redux/slices';

export interface HandleTokenErrorProps {
    error: AxiosError;
}

export const handleTokenError = ({ error }: HandleTokenErrorProps): boolean => {
    if (!error.isAxiosError) return false;
    const errorStringified = JSON.stringify(error.response);
    const isBadCredentials = errorStringified.includes('Bad credentials');
    if (isBadCredentials) {
        store.dispatch(logout({ refreshToken: true }));
        return true;
    }
    return false;
};
