import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../../styles';

type RuleNames = 'loading' | 'selectForm' | 'inputForm' | 'buttonForm';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        selectForm: {
            display: 'flex',
            gap: 8,
            marginBottom: 14,
            '& label': { color: theme.colors.textNeutral },
        },
        inputForm: {
            '& label': theme.colors.backgroundGray,
            marginBottom: 14,
            '@media (min-width: 1024px)': {
                marginBottom: 16,
            },
        },
        buttonForm: {
            display: 'flex',
            marginTop: 14,
            justifyContent: 'center',
            '& button': {
                maxWidth: 200,
            },
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
    }),
);

export default styles;
