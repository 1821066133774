import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from '../../redux';
import { Auth, login, logout } from '../../redux/slices';
import { getAccountForLogin } from '../../services/account/getAccountForLogin';
import { refreshToken } from '../../services/refreshToken/refreshToken';
import { getMe } from '../../services/me/getMe';

const useAuthHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signIn = async (data: Auth) => {
        const { refreshToken: shouldRefreshToken } =
            store.getState()?.auth || {};
        if (shouldRefreshToken) {
            await refreshToken(data.token);
        }
        const accountData = await getAccountForLogin(data.token);
        const meData = await getMe(data.token);
        dispatch(
            login({ ...accountData, ...meData, refreshToken: false, ...data }),
        );
        navigate('/home');
    };

    const signOut = () => {
        dispatch(logout({}));
        navigate('/login');
    };

    return {
        signIn,
        signOut,
    };
};

export default useAuthHook;
