import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'subtitle';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            display: 'flex',
            marginBottom: 24,
            alignItems: 'end',
            '& i': {
                fontSize: 18,
                color: theme.colors.borderColor,

                [theme.breakpoints.tablet]: {
                    marginTop: 16,
                    fontSize: 24,
                },
            },
            '& h1': {
                color: theme.colors.textNeutralPlaceholder,
                marginLeft: 12,
                fontSize: 18,
                fontWeight: 800,
                [theme.breakpoints.tablet]: {
                    fontSize: 20,
                },
            },

            [theme.breakpoints.tablet]: {
                marginBottom: 24,
            },
            '& Button': {
                marginLeft: 'auto',
                color: '#2F2D2D',
                alignSelf: 'center',
                '& i': {
                    fontSize: 12,
                    color: '#2F2D2D',
                },
            },
        },
        subtitle: {
            display: 'flex',
            fontWeight: '500',
            marginBottom: 24,
        },
    }),
);

export default styles;
