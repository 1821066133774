import React from 'react';
import styles from './styles';

export type AccountInfoLinePropsType = {
    label: string;
    value?: string;
    renderComponent?: () => React.ReactElement;
};

export const AccountInfoLine = ({
    label,
    value = '',
    renderComponent,
}: AccountInfoLinePropsType) => {
    const classes = styles();
    return (
        <div className={classes.informationLine}>
            <span className={classes.informationHeader}>{label}:</span>
            <span className={classes.informationData}>
                {renderComponent ? renderComponent() : value}
            </span>
        </div>
    );
};
