import { callPost } from '../graphql';
import { BAGY_PAY_VINDI_GET_SELLER_SPLITS } from '../graphql/query';

export const getSellerSplits = async () => {
    const { data: axiosData } = await callPost(
        BAGY_PAY_VINDI_GET_SELLER_SPLITS,
        {},
    );
    const { data } = axiosData;
    return data.bagyPayVindiGetSellerSplit;
};
