import { callPost } from '../graphql';
import { BAGY_PAY_VINDI_GET_SALES } from '../graphql/query';
import { GetSalesParamsType, GetSalesResultType } from './getSales.type';

export const getSales = async ({
    limit = 10,
    offset = 0,
    ...paramsLeft
}: GetSalesParamsType = {}): Promise<GetSalesResultType> => {
    const { data: axiosData } = await callPost(BAGY_PAY_VINDI_GET_SALES, {
        limit,
        offset,
        ...paramsLeft,
    });
    const { data } = axiosData;
    return data.bagyPayVindiGetSales;
};
