/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PropsWithChildren, useState } from 'react';
import { Icon } from '../Icon';
import styles from './styles';

interface CollapsableProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    tutorialUrl?: string;
}

const Collapsable = ({
    children,
    title,
    tutorialUrl,
}: PropsWithChildren<CollapsableProps>) => {
    const classes = styles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={classes.container}>
            <div className={classes.header} onClick={() => setIsOpen(!isOpen)}>
                <div className={classes.titleContainer}>
                    <span className={classes.title}>{title}</span>
                </div>
                <Icon
                    customStyles={`${classes.chevronDownIcon} ${
                        isOpen ? '-isOpen' : ''
                    }`}
                    iconName="icon-chevron-down"
                />
            </div>
            <div
                className={`${classes.contentContainer} ${
                    isOpen ? '-isOpen' : ''
                }`}
            >
                {children}
                {tutorialUrl && <a href={tutorialUrl}>{tutorialUrl}</a>}
            </div>
        </div>
    );
};

export default Collapsable;
