import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'menuContainer';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        menuContainer: {
            display: 'none',
            flexDirection: 'column',
            boxSizing: 'border-box',
            position: 'relative',
            padding: '16px 32px',
            width: 250,
            height: '100vh',
            backgroundColor: `${theme.colors.backgroundGray}`,
            [theme.breakpoints.desktop]: {
                display: 'flex',
            },
        },
    }),
);

export default styles;
