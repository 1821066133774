import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'header';
const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            padding: 4,
            color: theme.colors.textNeutralPlaceholder,
        },
        header: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 25,
        },
    }),
);
export default styles;
