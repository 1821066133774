/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Auth {
    token: string;
    email: string;
    name: string;
    blipId: number;
    storeId: number;
    refreshToken?: boolean;
}

const initialState: Auth = {
    token: '',
    email: '',
    name: '',
    blipId: 0,
    storeId: 0,
    refreshToken: false,
};

export const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<Auth>) => {
            const { email, token, name, blipId, storeId } = action.payload;
            state.email = email;
            state.token = token;
            state.name = name;
            state.blipId = blipId;
            state.storeId = storeId;
            state.refreshToken = false;
        },
        logout: (state, action: PayloadAction<{ refreshToken?: boolean }>) => {
            state.refreshToken = action.payload.refreshToken;
            state.email = initialState.email;
            state.token = initialState.token;
            state.name = initialState.name;
            state.storeId = initialState.storeId;
            state.blipId = initialState.blipId;
        },
    },
});

export const { login, logout } = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
