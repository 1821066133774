import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            boxSizing: 'border-box',
            position: 'relative',
            top: 72,
            padding: '32px 16px',
            height: '100%',
            maxHeight: '100vh',
            width: '100vw',
            overflowY: 'auto',
            [theme.breakpoints.desktop]: {
                top: 0,
                padding: 32,
            },
        },
    }),
);

export default styles;
