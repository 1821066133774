/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type GlobalModalKeyType = 'DIALOG_MODAL' | 'CONFIRM_MODAL';
type GlobalModalDataType = {
    title: string;
    message: string;
    buttonText: string;
    onClick?: () => void;
    onYesClick?: () => void;
    onNoClick?: () => void;
};

export type GlobalModalType = {
    data: GlobalModalDataType;
    modalType: GlobalModalKeyType;
    isOpen?: boolean;
};

const initialState: GlobalModalType = {
    modalType: 'DIALOG_MODAL',
    data: {
        buttonText: '',
        message: '',
        title: '',
        onClick: () => null,
    },
};

export const globalModalSlice = createSlice({
    name: 'GlobalModal',
    initialState,
    reducers: {
        showModal: (state, action: PayloadAction<GlobalModalType>) => {
            const { modalType, data } = action.payload;
            state.data = data;
            state.modalType = modalType;
            state.isOpen = true;
        },
        hideModal: (state) => {
            state.isOpen = false;
            state.data = initialState.data;
        },
    },
});

export const { showModal, hideModal } = globalModalSlice.actions;

const globlaModalReducer = globalModalSlice.reducer;

export default globlaModalReducer;
