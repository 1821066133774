import { callPost } from '../graphql';
import { BAGY_PAY_VINDI_SEARCH_BANK_ACCOUNT } from '../graphql/query';

export const getBankAccount = async () => {
    const { data: axiosData } = await callPost(
        BAGY_PAY_VINDI_SEARCH_BANK_ACCOUNT,
        {},
    );
    const { data } = axiosData;
    const { bankAccounts } = data.bagyPaySearchBankAccount;
    const bankAccount = bankAccounts[0];
    return bankAccount;
};
