import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container' | 'bankContainer' | 'header';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        container: {
            color: theme.colors.primaryPure,
            fontSize: 14,
        },

        bankContainer: {
            width: '100%',
            padding: 5,
            '& th': {
                color: theme.colors.textNeutral,
                fontWeight: 'bold',
            },
            '& th, td': {
                padding: '8px',
                textAlign: 'start',
            },
        },
        header: {
            alignItems: 'center',
            display: 'flex',
            textAlign: 'start',
            padding: 5,
            '& h1': {
                fontSize: 18,
                marginLeft: 12,
            },
            '& i': {
                padding: 6,
                fontSize: 12,
                color: theme.colors.primaryPure,
            },
        },
    }),
);

export default styles;
