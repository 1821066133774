import axios, { AxiosError } from 'axios';
import { DocumentNode } from 'graphql/language/ast';
import env from '../../constants';
import { store } from '../../redux';
import { ExpiredSessionError } from '../../utils/errors/classes/ExpiredSessionError';
import { handleTokenError } from '../../utils/errors/handleTokenError';

const GRAPHQL_URL = `${env.API_URL}/graphql`;

export async function callPost(
    query: DocumentNode,
    variables: any,
    token?: string,
) {
    const { auth } = store.getState();
    const tokenInternal = token || (auth && auth.token) || '';
    try {
        return await axios.post(
            GRAPHQL_URL,
            { query: query.loc?.source.body, variables },
            tokenInternal
                ? {
                      headers: { 'x-auth-token': tokenInternal },
                  }
                : undefined,
        );
    } catch (e) {
        const tokenError = handleTokenError({ error: e as AxiosError });
        if (tokenError) throw new ExpiredSessionError();

        // Add global error handler
        console.error(e);
        throw e;
    }
}
