/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentHeader from '../../components/ContentHeader';
import Table from '../../components/Table';
import styles from './styles';
import {
    DataConfigType,
    HideArrowType,
    TableHeader,
    TableProps,
} from '../../components/Table/types';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import { formatCurrency } from '../../utils/formatter';
import { getSales } from '../../services/getSales/getSales';
import {
    TRANSACTION_STATUS,
    TRANSACTION_STATUS_COLOR,
    TRANSACTION_STATUS_LABEL,
    TRANSACTION_STATUS_LABEL_MOBILE,
} from '../../constants';
import { PaginationType } from '../../services/types/pagination.type';
import { MEDIA_SIZES } from '../../styles';

export type TransactionsType = {
    transactionId: string;
    id?: number;
    buyerName: string;
    amount: number;
    observation: string;
    status: string;
    detailAction?: number;
};

const Transactions = () => {
    const classes = styles();
    const navigate = useNavigate();

    const CLASS_BY_TRANSACTION_STATUS = {
        [TRANSACTION_STATUS.CANCELED]: classes.verticalStatusCancelled,
        [TRANSACTION_STATUS.APPROVED]: classes.verticalStatusSuccess,
        [TRANSACTION_STATUS.WAITING_PAYMENT]: classes.verticalStatusWaiting,
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [total, setTotal] = useState(0);
    const [listTransactions, setListTransactions] = useState<
        TransactionsType[]
    >([]);

    const paginationType: PaginationType = {
        limit: 10,
        offset: (currentPage - 1) * 10,
    };
    const headers: TableHeader = {
        transactionId: { title: 'Transação', order: 0 },
        buyerName: { title: 'Comprador', order: 1 },
        amount: { title: 'Valor Total', order: 2 },
        status: { title: 'Status', order: 3 },
        observation: { title: 'Observação', order: 4 },
        detailAction: { title: 'Mais', order: 5 },
    };
    const idxColsHideOnMobile: number[] = [1, 4, 5];
    const hideArrowRight: HideArrowType = {
        media: 'tablet',
    };

    const dataConfig: DataConfigType<TransactionsType> = {
        transactionId: {
            renderComponent: ({ value, rowData }) => {
                const { status } = rowData;

                return (
                    <div>
                        <div
                            className={`${classes.verticalStatus} ${
                                CLASS_BY_TRANSACTION_STATUS[status] ||
                                classes.verticalStatusWaiting
                            }`}
                        />
                        <div>{value}</div>
                    </div>
                );
            },
        },
        amount: {
            renderComponent: ({ value }) => (
                <div>{formatCurrency(Number(value))}</div>
            ),
        },
        status: {
            renderComponent: ({ rowData }) => {
                const { status } = rowData;

                return (
                    <div
                        className={classes.statusTable}
                        style={{
                            // TODO: Passar para arquivo style
                            display: 'inline-block',
                            padding: '6px 12px',
                            borderRadius: 8,
                            color: TRANSACTION_STATUS_COLOR[status],
                            border: `1px solid ${TRANSACTION_STATUS_COLOR[status]}`,
                        }}
                    >
                        {`${
                            window.screen.width < MEDIA_SIZES.tablet
                                ? TRANSACTION_STATUS_LABEL_MOBILE[status]
                                : TRANSACTION_STATUS_LABEL[status]
                        }`}
                    </div>
                );
            },
        },
        detailAction: {
            renderComponent: () => (
                <Button
                    style={{
                        border: '1px solid #8D8989',
                        color: '#8D8989',
                        borderRadius: 8,
                        marginRight: 8,
                    }}
                    typeButton="link"
                    title="Detalhes"
                />
            ),
        },
    };

    const getTableProps = useCallback(
        (): TableProps<TransactionsType> => ({
            headers,
            idxColsHideOnMobile,
            hideArrowRight,
            listData: listTransactions,
            dataConfig,
            onClickRow: (data) => {
                const { detailAction: id } = data;
                navigate(`${id}`);
            },
        }),
        [listTransactions],
    );
    const loadTransactions = async ({ limit, offset }: PaginationType) => {
        setIsLoading(true);
        setListTransactions([]);
        const { sales, total: totalSales } = await getSales({
            limit,
            offset,
        });
        const transanstionsTable: TransactionsType[] =
            sales.map<TransactionsType>(
                ({ customer, orderNumber, status, sellerPrice, id }) => {
                    const { name: buyerName } = customer;
                    const { name: statusTransaction } = status;
                    return {
                        buyerName,
                        status: statusTransaction,
                        amount: sellerPrice,
                        transactionId: orderNumber,
                        observation:
                            TRANSACTION_STATUS_LABEL[statusTransaction],
                        detailAction: id,
                    };
                },
            );
        setTotal(totalSales);
        setIsLoading(false);
        setListTransactions(transanstionsTable);
    };
    useEffect(() => {
        loadTransactions(paginationType);
    }, [currentPage, total]);

    return (
        <div className={classes.container}>
            <ContentHeader iconName="icon-chat-money" title="Transações" />
            <div className={classes.tableContainer}>
                <Table
                    {...getTableProps()}
                    isLoading={isLoading}
                    hideArrowRight={{
                        media: 'tablet',
                    }}
                />
                <Pagination
                    onChangePage={(value) => setCurrentPage(value)}
                    currentPage={currentPage}
                    totalPerPage={10}
                    total={total}
                />
            </div>
        </div>
    );
};

export default Transactions;
