/* eslint-disable @typescript-eslint/no-unused-vars */
import { createUseStyles } from 'react-jss';
import { BagyPayThemeType } from '../../styles';

type RuleNames = 'container';

const styles = createUseStyles<RuleNames, unknown, BagyPayThemeType>(
    (theme) => ({
        '@keyframes lds-ellipsis1': {
            '0%': { transform: 'scale(0)' },
            '100%': { transform: 'scale(1)' },
        },
        '@keyframes lds-ellipsis2': {
            '0%': { transform: 'translate(0, 0)' },
            '100%': { transform: 'translate(24px, 0)' },
        },
        '@keyframes lds-ellipsis3': {
            '0%': { transform: 'scale(1)' },
            '100%': { transform: 'scale(0)' },
        },
        container: {
            '& .lds-ellipsis': {
                display: 'inline-block',
                position: 'relative',
                width: 80,
                height: 80,
            },
            '& .lds-ellipsis div': {
                position: 'absolute',
                top: 33,
                width: 13,
                height: 13,
                borderRadius: '50%',
                backgroundColor: theme.colors.primaryPure,
                animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
            },
            '& .lds-ellipsis div:nth-child(1)': {
                left: 8,
                animation: '$lds-ellipsis1 0.6s infinite',
            },
            '& .lds-ellipsis div:nth-child(2)': {
                left: 8,
                animation: '$lds-ellipsis2 0.6s infinite',
            },
            '& .lds-ellipsis div:nth-child(3)': {
                left: 32,
                animation: '$lds-ellipsis2 0.6s infinite',
            },
            '& .lds-ellipsis div:nth-child(4)': {
                left: 56,
                animation: '$lds-ellipsis3 0.6s infinite',
            },
        },
    }),
);

export default styles;
